import axios from 'axios';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import LoadingSpinner from '../common/Loading';
import log from '../core/log';

const Email = ({ step, emailRef, StoreEmail, answerObj, formData }) => {
  const [content, setContent] = useState('');
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const styles = step?.styles || {};

  const getRecallAnswer = (string) => {
    if (!string) return '';
    let str = string;
    Object.keys(answerObj).forEach((e) => {
      const variable = e;
      if (str.includes(variable)) {
        let answer = answerObj[e];
        str = str
          .replaceAll(variable, `<strong>${answer}</strong>`)
          .toLowerCase();
      }
    });
    return str;
  };
  const validateEmail = (e) => {
    e.preventDefault();
    const emailId = emailRef.current.value;
    if (!emailId) {
      setError('Please enter your email');
    } else {
      const pattern = /[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
      const result = pattern.test(emailId) && !emailId.includes(' ');
      if (result) {
        window.dataLayer.push({
          event: 'email_submission',
          element_type: 'click',
        });
        StoreEmail(emailRef.current.value, step, setError);
      } else setError('Please enter a valid email');
    }
  };
  const getContent = async (str) => {
    try {
      if (answerObj[str]) {
        const answer = answerObj[str].toLowerCase();
        let config = {
          method: 'post',
          url: process.env.REACT_APP_GET_CONTENT_DETAILS,
          headers: {
            'Content-Type': 'application/json',
          },
          data: { form_name: formData.form_name, content_name: answer },
        };

        let response = await axios(config);

        if (!response.data.success) {
          setLoading(false);
        } else {
          setContent(response.data.data.description);
          setLoading(false);
        }
      }
    } catch (err) {
      log('Error while getting content', err);
      setLoading(false);
    }
  };

  useEffect(() => {
    if (step?.extra_variable?.content) {
      getContent(step?.extra_variable?.content);
    } else {
      setLoading(false);
    }
  }, [step]);
  return (
    <>
      {loading && <LoadingSpinner />}
      {!loading && (
        <div
          style={{
            backgroundImage: `url(./images${
              step?.styles?.background_image || ''
            })`,
          }}
          className={`bg-cover bg-no-repeat min-h-screen bg-left-bottom relative`}
        >
          <div className="relative">
            <div className="header-logo max-w-[1200px] pt-2 pb-6 mx-4 lg:mx-auto">
              <Link to="/">
                <img src="./images/tapouts-logo.png" width="140px" />
              </Link>
            </div>

            <div className="lg:flex block mx-0">
              <div className="relative w-full lg:mx-0 lg:w-[40%] mt-0 lg:mt-10 lg:px-0 px-4 lg:ml-[120px] lg:float-left block lg:flex items-center text-center lg:text-left">
                <div>
                  <div className="flex lg:justify-start text-left">
                    <div className="">
                      <div className="text-3xl bookmania-bold lg:text-6xl font-black text-[#130049]">
                        Congratulations! 🥳
                      </div>
                      <div
                        dangerouslySetInnerHTML={{
                          __html: getRecallAnswer(step.heading),
                        }}
                        className="text-[26px] leading-[31.2px] lg:text-[32px] bookmania text-[#130049] font-bold mt-2"
                      ></div>
                    </div>
                  </div>
                  {step.sub_heading ? (
                    <div
                      className="mt-4 text-left"
                      dangerouslySetInnerHTML={{
                        __html: getRecallAnswer(step.sub_heading),
                      }}
                    ></div>
                  ) : (
                    ''
                  )}
                  {content ? (
                    <div
                      className="mt-12 text-left"
                      dangerouslySetInnerHTML={{
                        __html: getRecallAnswer(content),
                      }}
                    ></div>
                  ) : (
                    ''
                  )}
                  {error && error !== '200' && (
                    <div className="text-[#FE4254] font-black ">*{error}</div>
                  )}
                  {error && error === '200' && (
                    <strong className="text-[#FE4254]">
                      *Oops! Looks like you tried adding this child before.
                      <br></br>
                      Please select already taken assessment from the link
                      below.
                      <Link
                        className="underline cursor-pointer text-[#130049]"
                        to="/"
                      >
                        <div>Click here</div>
                      </Link>
                    </strong>
                  )}
                  <form className="relative mt-20 lg:mt-8 flex items-center">
                    <input
                      type="email"
                      ref={emailRef}
                      onChange={() => {
                        setError('');
                      }}
                      className="border-[#DBE0E4] border bg-[#FFFFFF] h-16 rounded-full pl-16 py-3 flex justify-between w-full pr-36 lg:pr-48"
                      placeholder="Enter email to get started!"
                    />

                    <div className="absolute top-5 left-8">
                      <img src="./images/mail_congrats.png" />
                    </div>
                    <button
                      onClick={(e) => validateEmail(e)}
                      className={`absolute right-2 top-2 bg-[${
                        styles?.btn_bg_colour || '#FE4254'
                      }] justify-center px-4 py-2 flex items-center h-12 w-[121px] lg:w-[153px] rounded-full text-center mx-auto`}
                    >
                      <div className="text-white text-base">
                        {styles?.btn_content || 'Scheduling'}
                      </div>
                    </button>
                  </form>
                  <div className="text-[10px] font-normal text-[#130049] italic mt-6">
                    *Disclaimer: tapouts is not psychotherapy nor is it intended
                    to replace any services provided by mental health
                    professionals.{' '}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

Email.propTypes = {
  emailRef: PropTypes.object,
  StoreEmail: PropTypes.func,
  step: PropTypes.object,
  answerObj: PropTypes.object,
  formData: PropTypes.object,
};

export default Email;
