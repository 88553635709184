/* 
Logging Function to log errors for monitoring 
Usage:
log(1, "Something bad happened.")
*/

const log = (errorCode, errorObj) => {
  /*
  var img = new Image();

    img.src = process.env.REACT_APP_LOG_URL + "?severity=" +
        encodeURIComponent(severity) +
        "&message=" + encodeURIComponent(message);
    */
  console.log(errorCode, ' Message to Server ', errorObj);
};

export default log;
