import {
  PaymentElement,
  useStripe,
  useElements,
} from '@stripe/react-stripe-js';
import PropTypes from 'prop-types';
import { useState } from 'react';
import { Link } from 'react-router-dom';

import LoadingSpinner from '../common/Loading';
const CheckoutForm = ({ stripeData, id, formName, step }) => {
  const [loading, setLoading] = useState();
  const [disable, setDisable] = useState(true);
  const [errorMessage, setErrorMessage] = useState(null);
  const stripe = useStripe();
  const elements = useElements();
  const onSubmit = async (event) => {
    event.preventDefault();
    setErrorMessage(null);
    setLoading(true);
    if (!stripe || !elements) {
      return;
    }
    const result = await stripe.confirmSetup({
      elements,
      confirmParams: {
        return_url: `${process.env.REACT_APP_STRIPE_RETURN_URL}/paymentloading?id=${id}&customerId=${stripeData.customerId}&priceId=${stripeData.priceId}&formName=${formName}&step_no=${step?.step_no}`,
      },
    });
    if (result.error) {
      // Show error to your customer (for example, payment details incomplete)
      console.log(result.error.message);
      setErrorMessage(result.error.message);
      setLoading(false);
    } else {
      // Your customer will be redirected to your `return_url`. For some payment
      // methods like iDEAL, your customer will be redirected to an intermediate
      // site first to authorize the payment, then redirected to the `return_url`.
    }
  };

  const eventCheck = (event) => {
    event ? setDisable(false) : setDisable(true);
  };

  window.onpopstate = function () {
    history.go(1);
  };

  return (
    <div className="min-h-screen payment-bg pb-12">
      {loading && <LoadingSpinner />}
      <div className="relative">
        <div className="flex relative ml-4 lg:ml-0 lg:max-w-[1200px] mx-auto lg:pl-20 pt-10">
          <div className="">
            <Link to="/">
              <img
                src="./images/tapouts-logo.png"
                width="138px"
                height="40px"
              />
            </Link>
          </div>
        </div>

        <form onSubmit={onSubmit}>
          <div className="bg-white relative  lg:-translate-x-1/2 lg:left-1/2 w-auto mt-10 lg:w-[520px] mx-4 lg:-mt-[40px] p-5 lg:p-8 z-10 rounded-[24px]">
            <div className="flex justify-center bookmania-bold items-center text-[#130049]">
              <div className="bg-[#FBCD41] py-2 rounded-[400px] px-8">
                <span className="text-[24px] lg:text-[40px] font-black">
                  Start
                </span>
              </div>
              <div className="text-[24px] lg:text-[40px] font-black pl-4">
                your
              </div>
            </div>
            <div className="text-center bookmania-bold flex justify-center items-center pt-2">
              <span>
                <img src="./images/payment_vector.png" />
              </span>
              <span className="text-[24px] lg:text-[40px] font-black pl-3 grade-text">
                free discovery{' '}
              </span>
            </div>
            <div className="text-center bookmania-bold flex justify-center items-center">
              <span className="text-[24px] lg:text-[40px] font-black grade-text">
                tapouts session
              </span>
            </div>
            <div className="py-2 hidden lg:block bookmania-bold font-black text-[24px] text-[#130049] text-center">
              Totally free. No commitment.
            </div>
            <div className="py-2 block lg:hidden bookmania-bold font-black text-[24px] text-[#130049] text-center">
              <div>Totally free.</div>
              <div>No commitment.</div>
            </div>
            <div className="pb-4 text-[#130049] text-center text-[16px] font-[400]">
              Cancel anytime
            </div>
            <PaymentElement
              id="payment-element"
              onFocus={(e) => {
                eventCheck(e);
              }}
            />
            {errorMessage && (
              <div className="text-center items-center mt-3 flex justify-center">
                <div>
                  <img src="./images/error-icon.png" />
                </div>
                <div className="pl-2 text-[#FE4254] font-[600] text-[16px]">
                  {errorMessage}
                </div>
              </div>
            )}
            <div className="flex justify-between mt-4 text-[#1B1D20] text-sm font-normal border-b border-[##DBE0E4] pb-4">
              <div>Total Due Today</div>
              <div>$0.00</div>
            </div>
            <div className="flex justify-between mt-4 text-[#1B1D20] text-sm font-normal pb-4">
              <div>Cost per month after trial</div>
              <div>$100.00/4 weeks</div>
            </div>
            <div className="text-center mt-2">
              <button
                type="submit"
                className={
                  'rounded-[80px] text-white p-4 w-[182px] mx-auto ' +
                  (!stripe || disable ? 'bg-gray-300' : 'bg-[#FE4254]')
                }
                disabled={!stripe || disable}
              >
                <span className="text-white text-base font-normal">
                  Start my free week
                </span>
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default CheckoutForm;

CheckoutForm.propTypes = {
  id: PropTypes.string.isRequired,
  stripeData: PropTypes.object,
  formName: PropTypes.string,
  step: PropTypes.object,
};
