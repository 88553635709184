import { BrowserRouter as Router } from 'react-router-dom';
import TapoutsRoutes from './components/core/TapoutsRoutes';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import CoreContextProvider from './components/context/CoreContext';

function App() {
  return (
    <Router>
      <ToastContainer
        position="top-center"
        autoClose={1500}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      <CoreContextProvider>
        <TapoutsRoutes />
      </CoreContextProvider>
    </Router>
  );
}
export default App;
