import Enrollement from './Enrollement';
import { retriveCookie } from '../../common/helper';

const Dashboard = () => {
  const form_name = process.env.REACT_APP_GET_TAPOUTS_FORM;
  const cookies = retriveCookie(form_name);
  return (
    <>
      <Enrollement form_name={form_name} cookies={cookies} />
    </>
  );
};
export default Dashboard;
