export function retriveCookie(name) {
  var cookies = decodeURIComponent(document.cookie).split(';');

  for (var i = 0; i < cookies.length; i++) {
    var c = cookies[i];
    while (c.charAt(0) == ' ') {
      c = c.substring(1);
    }
    if (c.indexOf(`${name}=`) == 0) {
      const str = c.replace(`${name}=`, '');
      return JSON.parse(str);
    }
  }
}

export const deleteCookie = function () {
  const name = process.env.REACT_APP_GET_TAPOUTS_FORM;
  document.cookie = name + '=;expires=Thu, 01 Jan 1970 00:00:01 GMT;';
  document.cookie =
    `${name}-answer` + '=;expires=Thu, 01 Jan 1970 00:00:01 GMT;';
};
