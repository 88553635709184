import axios from 'axios';
import PropTypes from 'prop-types';
import React, { useContext, useState, useEffect } from 'react';
import log from '../core/log';

export const CoreContext = React.createContext({});

const CoreContextProvider = ({ children }) => {
  const [data, setData] = useState({ formData: '', stepsData: [] });
  const [coreLoading, setCoreLoading] = useState(true);
  const getAllSteps = async () => {
    try {
      let config = {
        method: 'get',
        url: `${process.env.REACT_APP_TYPEFORM_DETAILS}?name=${process.env.REACT_APP_GET_TAPOUTS_FORM}`,
        headers: {},
      };

      const response = await axios(config);

      let apiResponse = response.data;
      let stepsData = apiResponse.steps;

      let formData = apiResponse;
      delete formData.steps;
      const resultData = [...stepsData];
      resultData.forEach((e, i) => {
        if (e?.topic_id) {
          e.index = `${i - e.parentIndex}`;
        } else {
          e.index = i + 1;
        }
      });

      setCoreLoading(false);
      setData({ formData: formData, stepsData: stepsData });
    } catch (err) {
      log('Error while fetching steps data', err);
      setCoreLoading(false);
    }
  };

  useEffect(() => {
    getAllSteps();
  }, []);

  const value = {
    data,
    coreLoading,
  };
  return <CoreContext.Provider value={value}>{children}</CoreContext.Provider>;
};

export const useAuth = () => {
  return useContext(CoreContext);
};

CoreContextProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default CoreContextProvider;
