import PropTypes from 'prop-types';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router';
import BackButton from '../common/BackButton';
import { deleteCookie } from '../common/helper';

const FreeText = ({
  getRecallAnswer,
  stepsData,
  getDefaultValue,
  storeAnswer,
  goBack,
  index,
}) => {
  const validations = stepsData.validations ? true : false;
  const styles = stepsData?.styles || {};
  const navigate = useNavigate();

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({ defaultValues: { text: getDefaultValue(stepsData) } });
  const onSubmit = (obj) => {
    const finalData = {
      step_id: stepsData.id,
      type: stepsData.type,
      step_no: stepsData.step_no,
      variable: stepsData.variable ?? '',
      answer: obj.text,
    };
    storeAnswer(finalData, stepsData);
    reset({ text: '' });
  };
  const ErrorMessage = validations
    ? {
        required: '*Please Enter Your Answer',
        minLength: `*Please Enter your Answer with minimum length of
      ${stepsData.validations.min_character}`,
        maxLength: `*Please Enter your Answer with maximum length of 
      ${stepsData.validations.max_character}`,
      }
    : {};

  const restart = () => {
    deleteCookie();
    navigate('/');
  };

  return (
    <>
      <form
        className="flex flex-col min-h-[65vh] justify-between"
        onSubmit={handleSubmit(onSubmit)}
      >
        <div className="z-10 transition-fade w-full lg:w-[600px] delay-500 mx-auto relative lg:bg-white p-6 rounded-[24px] lg:shadow-sm">
          <div className="flex items-center">
            <div>
              {stepsData?.index && (
                <div
                  style={{
                    color: stepsData?.styles?.secondary_colour || '#130049',
                    backgroundColor:
                      stepsData?.styles?.primary_colour || '#FBCD41',
                  }}
                  className="rounded-full w-8 h-8 text-center flex justify-center items-center"
                >
                  <span className="text-lg">{stepsData?.index}</span>
                </div>
              )}
            </div>
            <div className="ml-1 lg:ml-2 text-[#130049]">
              <div
                dangerouslySetInnerHTML={{
                  __html: getRecallAnswer(
                    `${stepsData.heading}${
                      stepsData?.validations?.required
                        ? "<span style='color :#FE4254' >*</span>"
                        : ''
                    }`
                  ),
                }}
                className="text-lg"
              ></div>
              {stepsData.sub_heading ? (
                <div
                  dangerouslySetInnerHTML={{
                    __html: getRecallAnswer(stepsData.sub_heading),
                  }}
                  className="text-[12px] pt-8"
                ></div>
              ) : (
                ''
              )}
            </div>
          </div>
          {validations && errors?.text && (
            <div className="text-red-600 font-bold mt-4 ml-12">
              {ErrorMessage[errors.text.type]}
            </div>
          )}
          <div className="mt-8">
            <input
              {...register(
                'text',
                validations
                  ? {
                      required: stepsData.validations.required,
                      minLength:
                        stepsData.validations.min_character !== ''
                          ? stepsData.validations.min_character
                          : 0,
                      maxLength:
                        stepsData.validations.max_character !== ''
                          ? stepsData.validations.max_character
                          : Infinity,
                    }
                  : {}
              )}
              placeholder={
                stepsData?.placeholder ? stepsData?.placeholder : 'Type here'
              }
              className="w-full border rounded-full p-3 outline-none"
            />
          </div>
          {stepsData?.index && index !== 0 && (
            <div className="text-xs mt-6 underline font-normal ">
              <span onClick={restart} className="cursor-pointer">
                Restart Assessment
              </span>
            </div>
          )}
        </div>
        <div className="lg:max-w-[600px] pb-[64px] text-lg w-full mt-10 px-5 lg:px-0 mx-auto flex justify-between items-center">
          {(index !== 0 || !stepsData.index) && <BackButton goBack={goBack} />}
          <div
            className={`mt-8 bg-[${
              styles?.btn_bg_colour || '#FE4254'
            }]   px-4 py-2 flex items-center  ${
              !index && 'mx-auto'
            } w-24 rounded-full`}
          >
            <button
              type="submit"
              className="text-white text-base flex items-center"
            >
              <span className="pl-2">{styles?.btn_content || 'Next'}</span>

              <span className="pl-2">
                <svg
                  width="14"
                  height="10"
                  viewBox="0 0 17 12"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M15.5 5.912H1.5"
                    stroke="white"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M10.5 10.912L15.5 5.912"
                    stroke="white"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M10.5 0.912003L15.5 5.912"
                    stroke="white"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </span>
            </button>
          </div>
        </div>
      </form>
    </>
  );
};

FreeText.propTypes = {
  getRecallAnswer: PropTypes.func,
  stepsData: PropTypes.object,
  getDefaultValue: PropTypes.func,
  storeAnswer: PropTypes.func,
  goBack: PropTypes.func,
  index: PropTypes.number,
};

export default FreeText;
