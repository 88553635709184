import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

const Topic = ({ step, index, setIndexQueue, setIndex, getRecallAnswer }) => {
  const headingSplit = step?.heading.includes(',')
    ? step?.heading?.split(',')
    : [];
  const styles = step?.styles || {};
  return (
    <>
      <div className="header-logo max-w-[1200px] mx-4 lg:mx-auto pt-2 lg:pt-8">
        <Link to="/">
          <img src="./images/tapouts-logo.png" width="140px" />
        </Link>
      </div>

      <div className="lg:flex-row flex flex-col justify-between h-[calc(100vh-72px)] max-w-[1200px]  lg:mx-auto bookmania">
        <div className="relative lg:mx-auto mt-12 lg:mt-0 lg:float-left block lg:flex items-center text-center lg:text-left">
          <div className="z-[99] mx-4 lg:mx-0">
            <div className="flex justify-center lg:justify-start text-left">
              <div className="text-3xl w-auto lg:w-[600px] lg:text-5xl bookmania-bold font-bold text-[#130049]">
                <span
                  dangerouslySetInnerHTML={{
                    __html: getRecallAnswer(
                      headingSplit.length !== 0
                        ? `<strong>${headingSplit[0]}</strong>,`
                        : ''
                    ),
                  }}
                  style={{ color: step?.styles?.primary_colour || '#FBCD41' }}
                ></span>
                <span
                  dangerouslySetInnerHTML={{
                    __html: getRecallAnswer(
                      headingSplit.length !== 0
                        ? headingSplit[1]
                        : step?.heading
                    ),
                  }}
                ></span>
              </div>
            </div>
            {step.sub_heading ? (
              <div
                dangerouslySetInnerHTML={{
                  __html: getRecallAnswer(step.sub_heading),
                }}
                className="mt-4 lg:mt-8 text-base font-normal text-[#130049] text-left proxima-font"
              ></div>
            ) : (
              ''
            )}
            <div
              onClick={() => {
                setIndexQueue((current) => [...current, index + 1]);
                setIndex(index + 1);
              }}
              className={`mt-4 lg:mt-8 bg-[${
                styles?.btn_bg_colour || '#FE4254'
              }]  px-4 py-2 proxima-nova cursor-pointer flex justify-center h-12 items-center w-[109px] mx-0 rounded-full text-center`}
            >
              <button className="text-white font-normal text-base">
                {styles?.btn_content || 'Start'}
              </button>
              <div className="pl-2 font-bold">
                <svg
                  width="14"
                  height="10"
                  viewBox="0 0 17 12"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M15.5 5.912H1.5"
                    stroke="white"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M10.5 10.912L15.5 5.912"
                    stroke="white"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M10.5 0.912003L15.5 5.912"
                    stroke="white"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </div>
            </div>
          </div>
        </div>

        <div className="w-auto mt-[120px]  lg:w-[60%] lg:mt-0 lg:mx-0 relative lg:initial">
          <div className="overflow-hidden lg:fixed bottom-0 right-0 lg:overflow-scroll flex justify-center">
            <img src={`./images${step.image_url}`} className="" />
          </div>
        </div>
      </div>
    </>
  );
};

Topic.propTypes = {
  step: PropTypes.string,
  getRecallAnswer: PropTypes.func,
  index: PropTypes.number,
  setIndexQueue: PropTypes.func,
  setIndex: PropTypes.func,
};

export default Topic;
