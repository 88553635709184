const pageNotFound = () => {
  return (
    <div className="w-full">
      <div className="flex flex-row items-center justify-center h-screen text-center bg-indigo-50 lg:text-left">
        <div>
          <svg
            width="540"
            height="auto"
            viewBox="0 0 840 726.849"
            className="mt-40"
          >
            <g>
              <g>
                <g>
                  <path
                    d="M182.591,294.534c-23.792,0-43.149,19.356-43.149,43.149c0,23.792,19.357,43.149,43.149,43.149
        s43.149-19.356,43.149-43.149C225.74,313.89,206.383,294.534,182.591,294.534z M182.591,362.008
        c-13.412,0-24.325-10.911-24.325-24.325s10.911-24.325,24.325-24.325c13.412,0,24.325,10.911,24.325,24.325
        C206.916,351.096,196.003,362.008,182.591,362.008z"
                  />
                  <path
                    d="M458.741,315.032c0.055-1.948,0.09-3.902,0.09-5.863c0-29.257-6.062-57.329-17.995-83.602
        c15.578-11.141,24.945-29.05,24.945-48.638c0-5.198-4.213-9.412-9.412-9.412s-9.412,4.214-9.412,9.412
        c0,12.449-5.529,23.902-14.844,31.596c-12.814-22.342-29.955-42.305-50.165-58.349c1.304-7.379,1.973-14.838,1.973-22.253
        c0-42.267-20.609-79.803-52.301-103.109c7.243-2.97,15.246-3.829,23.171-2.327c5.107,0.968,10.032-2.386,11.002-7.494
        c0.969-5.106-2.387-10.032-7.494-11.001c-15.89-3.013-31.993,0.454-45.116,9.522C295.963,4.874,276.541,0,255.999,0
        c-20.542,0-39.964,4.874-57.181,13.515c-13.123-9.068-29.227-12.535-45.116-9.522c-5.106,0.968-8.462,5.893-7.494,11.001
        c0.969,5.107,5.894,8.462,11.002,7.494c7.926-1.505,15.93-0.645,23.171,2.325c-31.691,23.306-52.301,60.843-52.301,103.11
        c0,7.414,0.669,14.874,1.971,22.252c-20.209,16.044-37.35,36.008-50.164,58.351c-9.315-7.694-14.846-19.147-14.846-31.597
        c0-5.198-4.214-9.412-9.412-9.412c-5.199,0-9.412,4.214-9.412,9.412c0,19.588,9.368,37.497,24.946,48.638
        c-11.934,26.274-17.995,54.346-17.995,83.602c0,1.961,0.035,3.914,0.09,5.863c-13.967,11.326-22.18,28.317-22.18,46.483
        c0,5.198,4.213,9.412,9.412,9.412s9.412-4.214,9.412-9.412c0-7.464,2.032-14.637,5.712-20.867
        c5.044,32.222,17.703,61.948,36.066,87.286c-12.013,11.229-18.982,26.957-18.982,43.703c0,5.198,4.213,9.412,9.412,9.412
        s9.412-4.214,9.412-9.412c0-10.973,4.366-21.311,11.944-28.929c37.204,42.443,91.789,69.291,152.533,69.291
        s115.327-26.846,152.533-69.29c7.578,7.619,11.944,17.955,11.944,28.928c0,5.198,4.213,9.412,9.412,9.412
        s9.412-4.214,9.412-9.412c0-16.747-6.967-32.475-18.982-43.703c18.366-25.337,31.025-55.063,36.067-87.285
        c3.679,6.228,5.711,13.401,5.711,20.864c0,5.198,4.213,9.412,9.412,9.412s9.412-4.214,9.412-9.412
        C480.919,343.349,472.706,326.356,458.741,315.032z M392.895,186.245c9.796,10.906,18.288,22.966,25.221,35.831
        c-4.25,7.578-12.363,12.458-21.234,12.458c-13.412,0-24.325-10.911-24.325-24.325
        C372.557,198.181,381.385,188.16,392.895,186.245z M348.073,69.462c5.764,9.047,10.228,18.997,13.138,29.584
        c-1.949,0.813-4.043,1.254-6.179,1.254c-8.94,0-16.212-7.272-16.212-16.212C338.82,77.673,342.562,72.076,348.073,69.462z
         M163.926,69.462c5.512,2.614,9.254,8.211,9.254,14.626c0,8.94-7.272,16.212-16.212,16.212c-2.136,0-4.23-0.442-6.179-1.254
        C153.698,88.458,158.162,78.508,163.926,69.462z M147.378,117.774c3.096,0.886,6.32,1.349,9.589,1.349
        c19.318,0,35.036-15.716,35.036-35.036c0-12.461-6.526-23.512-16.497-29.728c19.963-21.825,48.657-35.536,80.494-35.536
        c31.837,0,60.531,13.711,80.494,35.535c-9.97,6.216-16.497,17.268-16.497,29.727c0,19.319,15.716,35.036,35.036,35.036
        c3.268,0,6.493-0.463,9.589-1.349c0.31,3.342,0.477,6.726,0.477,10.148c0,6.764-0.645,13.578-1.917,20.309
        c-36.203,22.242-80.049,30.203-107.179,30.203c-27.131,0-70.976-7.959-107.179-30.203c-1.274-6.731-1.917-13.547-1.917-20.309
        C146.903,124.499,147.068,121.116,147.378,117.774z M119.105,186.245c11.51,1.916,20.337,11.937,20.337,23.964
        c0,13.412-10.911,24.325-24.325,24.325c-8.871,0-16.983-4.878-21.234-12.456C100.816,209.212,109.311,197.152,119.105,186.245z
         M132.309,445.287c1.999-0.525,4.066-0.804,6.16-0.804c13.05,0,23.701,10.337,24.27,23.248
        C151.828,461.289,141.63,453.765,132.309,445.287z M215.226,488.616c-11.952-2.714-23.466-6.592-34.415-11.514
        c0.531-2.716,0.807-5.484,0.807-8.294c0-23.793-19.357-43.149-43.149-43.149c-7.167,0-14.177,1.796-20.412,5.146
        c-28.651-32.453-46.065-75.046-46.065-121.637c0-23.696,4.376-46.532,13.01-68.11c7.908,7.694,18.649,12.298,30.115,12.298
        c23.792,0,43.149-19.356,43.149-43.149c0-16.988-9.917-31.75-24.267-38.777c2.293-2.035,4.641-4.006,7.033-5.923
        c35.432,21.18,76.199,30.078,106.223,31.528v260.501C240.203,471.069,228.927,481.999,215.226,488.616z M255.999,493.176
        c-4.38,0-8.722-0.164-13.027-0.467c4.811-4.151,9.186-8.799,13.027-13.882c3.841,5.082,8.216,9.731,13.027,13.882
        C264.722,493.012,260.379,493.176,255.999,493.176z M349.259,467.732c0.568-12.913,11.219-23.25,24.27-23.25
        c2.094,0,4.162,0.28,6.16,0.804C370.37,453.765,360.171,461.29,349.259,467.732z M393.943,430.806
        c-6.236-3.352-13.246-5.148-20.414-5.148c-23.792,0-43.149,19.356-43.149,43.149c0,2.81,0.276,5.579,0.806,8.294
        c-10.949,4.922-22.462,8.801-34.415,11.514c-12.867-6.214-23.597-16.232-30.696-28.633V196.968
        c29.891-1.646,69.98-10.589,104.891-31.459c2.391,1.917,4.74,3.886,7.033,5.922c-14.349,7.027-24.269,21.79-24.269,38.778
        c0,23.792,19.357,43.149,43.149,43.149c11.466,0,22.208-4.604,30.115-12.299c8.634,21.577,13.011,44.414,13.011,68.11
        C440.008,355.759,422.594,398.353,393.943,430.806z"
                  />
                  <path
                    d="M329.408,294.534c-23.792,0-43.149,19.356-43.149,43.149c0,23.792,19.357,43.149,43.149,43.149
        s43.149-19.356,43.149-43.149C372.557,313.89,353.2,294.534,329.408,294.534z M329.408,362.008
        c-13.412,0-24.325-10.911-24.325-24.325s10.911-24.325,24.325-24.325c13.412,0,24.325,10.911,24.325,24.325
        C353.733,351.096,342.821,362.008,329.408,362.008z"
                  />
                </g>
              </g>
            </g>
          </svg>
        </div>
        <div className="text-black mt-10 lg:mt-0">
          <div className="text-8xl font-medium">Oops!</div>
          <div className="text-xl lg:text-3xl font-medium mt-5">
            Error 404!!!
          </div>
          <div className="text-lg mt-3">Page Not Found.</div>
          <button
            onClick={() => (window.location.href = '/')}
            className="w-50 p-3 mt-3 text-lg text-white transition-all duration-150 ease-linear rounded-md shadow outline-none bg-blue-900 hover:bg-yellow-500 focus:outline-none"
          >
            Back to Home
          </button>
        </div>
      </div>
    </div>
  );
};
export default pageNotFound;
