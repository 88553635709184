import axios from 'axios';
import { useEffect } from 'react';
import { useNavigate } from 'react-router';
import { retriveCookie } from '../common/helper';
import LoadingSpinner from '../common/Loading';
import log from '../core/log';

const PaymentLoading = () => {
  const urlParams = new URLSearchParams(window.location.search);
  const responsedocID = urlParams.get('id');
  const formName = urlParams.get('formName');
  const priceId = urlParams.get('priceId');
  const setupIntentKey = urlParams.get('setup_intent');
  const step_no = urlParams.get('step_no');
  const paymentURL = process.env.REACT_APP_STRIPE_PAYMENT_URL;
  const navigate = useNavigate();
  const cookies = retriveCookie(formName);
  const customer = cookies?.customer;

  const deleteCookie = function (name) {
    document.cookie =
      `${name}-answer` + '=;expires=Thu, 01 Jan 1970 00:00:01 GMT;';
  };

  const setSubscription = async () => {
    try {
      console.log('subscription started');
      const setupIntent = await fetch(paymentURL + '/get-setupIntent', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          setupIntentKey: setupIntentKey,
        }),
      }).then((res) => res.json());
      console.log(setupIntent, 'setupIntent');
      const updateCustomer = await fetch(paymentURL + '/update-customer', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          paymentMethod: setupIntent.payment_method,
          customerId: setupIntent.customer,
        }),
      });
      console.log(updateCustomer, 'customer has been created');
      const subscription = await fetch(paymentURL + '/create-subscription', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          customerId: setupIntent.customer,
          priceId: priceId,
        }),
      }).then((res) => res.json());
      console.log(subscription);
      let obj = {
        subscription: true,
        subscription_id: subscription.id,
        subscription_status: 'hold',
      };
      let data = {
        formData: obj,
        formName: formName,
        responseDocId: responsedocID,
      };
      let config = {
        method: 'post',
        url: process.env.REACT_APP_TAPOUTS_FORM_RESPONSE,
        headers: {
          'Content-Type': 'application/json',
        },
        data: data,
      };
      await axios(config)
        .then(function (result) {
          const customerExist = {
            ...customer,
            ...result.data.formData,
          };
          document.cookie =
            `${formName}=` +
            JSON.stringify({ ...cookies, ...{ customer: customerExist } });
          console.log('Subscription status updated');
        })
        .catch(function (error) {
          console.log(error);
          return;
        });
      deleteCookie(formName);
      navigate('/success', {
        state: {
          step_no: step_no,
          form: formName,
        },
      });
      window.dataLayer.push({
        event: 'payment_success',
        element_type: 'click',
      });
      console.log('subscription end');
    } catch (err) {
      log('Error while creating subscription', err);
    }
  };

  const getTapoutsFormResponse = async (responseDocId, formName) => {
    const responseData = await fetch(
      process.env.REACT_APP_GET_TAPOUTS_FORM_RESPONSE,
      {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          form_name: formName,
          doc_id: responseDocId,
        }),
      }
    ).then((res) => res.json());
    if (!responseData.success) {
      console.log('Api error!! ' + responseData.message);
    } else {
      if (!responseData.data.subscription) {
        await setSubscription();
      } else {
        navigate('/enrollment', {
          state: {
            step_no: step_no,
            form: formName,
          },
        });
      }
    }
  };
  useEffect(() => {
    if (responsedocID) {
      getTapoutsFormResponse(responsedocID, formName);
    } else {
      navigate('/');
      console.log('no subscription is created');
    }
  }, []);
  return <LoadingSpinner />;
};

export default PaymentLoading;
