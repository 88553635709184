import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router';
import { Link } from 'react-router-dom';
import { deleteCookie, retriveCookie } from '../../common/helper';

const PodStaticSuccess = () => {
  const location = useLocation();
  const { form } = location.state || '';
  const cookies = retriveCookie(form);
  const customer = cookies?.customer;
  const navigate = useNavigate();

  useEffect(() => {
    if (!customer || !cookies) navigate('/');
    deleteCookie();
  }, [location]);

  return (
    <div className="relative success-bg h-screen">
      <div className="header-logo max-w-[1200px] mx-10 lg:mx-auto pt-8">
        <Link to="/">
          <img src="./images/tapouts-logo.png" width="140px" />
        </Link>
      </div>

      <div className="lg:flex max-w-[1200px] lg:min-h-[550px] h-calc-enrolment items-center px-4 lg:px-0 justify-between block mx-0 lg:mx-auto ">
        <div className="relative ml-0 block lg:z-10  lg:flex items-center mt-6 text-center lg:text-left lg:mt-0">
          <div className="text-left lg:pr-[40%]">
            <div
              dangerouslySetInnerHTML={{
                __html: `YAY! You've taken the first step in helping ${
                  customer?.child_name
                    ? customer?.child_name.charAt(0).toUpperCase() +
                      customer?.child_name.slice(1)
                    : ''
                } to <span style='color: #FE4254 '>THRIVE</span>.`,
              }}
              className="text-[32px] lg:text-[40px] bookmania-bold font-black leading-[110%] text-[#130049]"
            ></div>
            <div className="text-[32px] lg:text-[40px] bookmania-bold font-black leading-[110%] text-[#130049]">
              {`We'll take it from here!`}
            </div>
            <div className="text-2xl font-normal leading-[110%] text-[#130049] mt-4 lg:mt-8">
              {' '}
              <strong>
                {' '}
                {customer?.child_name
                  ? customer?.child_name.charAt(0).toUpperCase() +
                    customer?.child_name.slice(1)
                  : ''}
              </strong>
              {` has been added to the waitlist and we'll be in touch with
              more information shortly.`}
            </div>
            <div className="relative lg:mt-4 mt-8 flex items-center">
              <a href="https://tapouts.com/">
                <button className="bg-[#FE4254] justify-center px-4 py-2 flex items-center w-[182px] h-12 rounded-full">
                  <div className="text-white text-base">Access the site</div>
                  <div className="pl-3">
                    <svg
                      width="14"
                      height="10"
                      viewBox="0 0 17 12"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M15.5 5.912H1.5"
                        stroke="white"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M10.5 10.912L15.5 5.912"
                        stroke="white"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M10.5 0.912003L15.5 5.912"
                        stroke="white"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                  </div>
                </button>
              </a>
            </div>
          </div>
        </div>
        <div className="fixed top-0 hidden lg:block right-0 success-right"></div>
      </div>
    </div>
  );
};

export default PodStaticSuccess;
