import React, { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router';
import { Link } from 'react-router-dom';
import { retriveCookie } from '../common/helper';

const SuccessPage = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { form } = location.state || '';
  const { step_no } = location.state || '';
  const cookies = retriveCookie(form);
  const customer = cookies?.customer;

  useEffect(() => {
    if (!form || !step_no) navigate('/');
  }, [location]);

  const moveToNextStep = () => {
    navigate('/enrollment', {
      state: {
        step_no: step_no,
        form: form,
      },
    });
  };
  return (
    <div className="relative payment-success-bg">
      <div className="header-logo lg:max-w-[1300px] px-4 lg:mx-auto pt-3 lg:pt-8 -z-1">
        <Link to="/">
          <img src="./images/tapouts-logo.png" width="140px" />
        </Link>
      </div>

      <div className="lg:flex max-w-[1300px] lg:mt-20 items-center px-4 justify-between block mx-0 lg:mx-auto ">
        <div className="relative ml-0 block lg:z-10 flex flex-col justify-between lg:flex items-center text-center lg:text-left mt-0">
          <div>
            <div className="flex justify-end w-auto lg:w-[438px]">
              <img src="../images/yellow_vector.png" />
            </div>
            <div className="flex items-center lg:-mt-4">
              <div className="text-[40px] bookmania-bold lg:text-[64px] font-black text-[#130049]">
                thank you
              </div>
              <div>
                <div className="bg-[#FBCD41] flex justify-end rounded-full w-[128px] h-12 ml-4">
                  <svg
                    width="48"
                    height="48"
                    viewBox="0 0 48 48"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M24 40C32.8366 40 40 32.8366 40 24C40 15.1634 32.8366 8 24 8C15.1634 8 8 15.1634 8 24C8 32.8366 15.1634 40 24 40ZM24 42C33.9411 42 42 33.9411 42 24C42 14.0589 33.9411 6 24 6C14.0589 6 6 14.0589 6 24C6 33.9411 14.0589 42 24 42Z"
                      fill="#130049"
                    />
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M16.6949 28C18.25 31.8319 21.1142 34 24 34C26.8858 34 29.75 31.8319 31.3051 28H16.6949ZM24 36C19.9619 36 16.4618 32.9521 14.7419 28.5C14.7254 28.4572 14.709 28.4143 14.6928 28.3713C14.2445 27.18 15.1998 26 16.4727 26H31.5273C32.8002 26 33.7555 27.18 33.3072 28.3713C33.291 28.4143 33.2746 28.4572 33.2581 28.5C31.5382 32.9521 28.0381 36 24 36Z"
                      fill="#130049"
                    />
                    <path
                      d="M21.3023 22.4421C21.4323 22.0844 21.3934 21.6474 21.2857 21.2489C21.1734 20.8333 20.9666 20.3855 20.6753 19.9696C20.0947 19.1404 19.1318 18.3781 17.831 18.3234C16.4842 18.2669 15.4778 19.1216 14.8578 20.0288C14.5433 20.4891 14.3095 20.987 14.1629 21.4451C14.0202 21.8907 13.9445 22.3469 13.9944 22.7115C14.0175 22.8807 14.1254 23.0264 14.2804 23.0978C14.4355 23.1693 14.6163 23.1567 14.7599 23.0645C15.1593 22.8081 15.579 22.4572 15.9668 22.133C16.1434 21.9853 16.3135 21.8432 16.4719 21.7181C17.0342 21.274 17.4717 21.0209 17.8345 21.0032C18.1791 20.9863 18.5774 21.1839 19.0791 21.5592C19.2585 21.6935 19.4326 21.8355 19.6129 21.9827C19.6772 22.0351 19.7422 22.0882 19.8085 22.1417C20.0523 22.3386 20.3119 22.5409 20.5736 22.6991C20.7016 22.7766 20.8576 22.7926 20.9987 22.7429C21.1398 22.6931 21.2512 22.5827 21.3023 22.4421Z"
                      fill="#130049"
                    />
                    <path
                      d="M26.6133 22.4421C26.4833 22.0844 26.5222 21.6474 26.6299 21.2489C26.7422 20.8333 26.949 20.3855 27.2403 19.9696C27.8209 19.1404 28.7839 18.3781 30.0846 18.3234C31.4314 18.2669 32.4378 19.1216 33.0578 20.0288C33.3724 20.4891 33.6061 20.987 33.7528 21.4451C33.8955 21.8907 33.9711 22.3469 33.9212 22.7115C33.8981 22.8807 33.7902 23.0264 33.6352 23.0978C33.4802 23.1693 33.2994 23.1567 33.1557 23.0645C32.7564 22.8081 32.3366 22.4572 31.9488 22.133C31.7722 21.9853 31.6021 21.8432 31.4437 21.7181C30.8814 21.274 30.4439 21.0209 30.0811 21.0032C29.7366 20.9863 29.3382 21.1839 28.8366 21.5592C28.6572 21.6935 28.4831 21.8355 28.3027 21.9827C28.2385 22.0351 28.1734 22.0882 28.1071 22.1417C27.8633 22.3386 27.6037 22.5409 27.3421 22.6991C27.214 22.7766 27.0581 22.7926 26.917 22.7429C26.7759 22.6931 26.6645 22.5827 26.6133 22.4421Z"
                      fill="#130049"
                    />
                  </svg>
                </div>
              </div>
            </div>
            <div className="flex items-center text-[40px] lg:text-[64px] bookmania-bold font-black text-[#130049]">
              <div>for</div>
              <div className="px-4">
                <img src="images/payment-vector-congrats.png" />
              </div>
              <div className="text-[#FE4254]">signing up</div>
            </div>
            <div className="w-auto text-left lg:w-[600px] mt-3 lg:mt-6 font-normal proxima-nova">
              <div className="text-[#130049] font-bold text-base lg:text-3xl">
                We have received your payment information and{' '}
                {customer?.child_name || 'Child'} is now signed up!
              </div>
              <div className="text-[#130049] text-sm lg:text-base mt-4">
                Please take a moment to answer a few additional questions.
              </div>
            </div>
            <div className="relative mt-8 flex items-center justify-end lg:mt-[120px]">
              <button
                onClick={moveToNextStep}
                className="bg-[#FE4254] justify-center px-4 py-2 flex items-center w-[109px] h-12 rounded-full"
              >
                <div className="w-20 text-white text-base">Next</div>
                <div className="font-bold">
                  <svg
                    width="14"
                    height="10"
                    viewBox="0 0 17 12"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M15.5 5.912H1.5"
                      stroke="white"
                      strokeWidth="1.5"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M10.5 10.912L15.5 5.912"
                      stroke="white"
                      strokeWidth="1.5"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M10.5 0.912003L15.5 5.912"
                      stroke="white"
                      strokeWidth="1.5"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </div>
              </button>
            </div>
          </div>
          <div className="lg:hidden block">
            <img src="./images/payment_success_mobile.png" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default SuccessPage;
