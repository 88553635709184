import React from 'react';
import PropTypes from 'prop-types';
function BackButton({ goBack }) {
  return (
    <>
      <div
        onClick={goBack}
        className="mt-8 bg-transparent left-4 border border-[#1E1E1E] cursor-pointer px-4 py-2 flex items-center w-24 rounded-full"
      >
        <div className="pr-2 font-bold">
          <svg
            width="16"
            height="12"
            viewBox="0 0 16 12"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M1 6H15"
              stroke="#130049"
              strokeWidth="1.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M6 1L1 6"
              stroke="#130049"
              strokeWidth="1.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M6 11L1 6"
              stroke="#130049"
              strokeWidth="1.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        </div>
        <div className="text-[#130049] text-base">Back</div>
      </div>
    </>
  );
}

BackButton.propTypes = {
  goBack: PropTypes.func,
};

export default BackButton;
