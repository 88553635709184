import PropTypes from 'prop-types';
import { useState } from 'react';
import { Link } from 'react-router-dom';
import BackButton from '../../../common/BackButton';

const ChildResponse = ({ childs, submit, setAddedChildResponse }) => {
  const goBack = () => {
    setAddedChildResponse([]);
  };
  const [selectedChoice, setSelectedChoice] = useState('');
  return (
    <div className="bg-cover child-taken bg-no-repeat min-h-screen bg-center-bottom relative flex flex-col justify-between">
      <div className="flex justify-center items-center py-10">
        <Link to="/">
          <img src="./images/tapouts-logo.png" width="138px" height="40px" />
        </Link>
      </div>

      <div className="z-10 lg:w-[600px] mx-auto relative lg:bg-white p-6 rounded-[24px] lg:shadow-sm">
        <div className="flex bookmania justify-start items-center text-left">
          <div className="text-[26px] font-black text-[#130049]">
            Great to see you again!
          </div>
          <span className="pl-2">
            <img src="./images/happy.png" width="36px" height="36px" />
          </span>
        </div>
        <div className="pt-6 text-base text-[#130049]">
          Select which assessment you would like to continue.
        </div>
        {childs.map((eachChild) => {
          return (
            <>
              <div className="py-6 border-b flex items-center border-[#DBE0E4]">
                <div>
                  <input
                    key={eachChild.responseDocId}
                    type="radio"
                    value="coach"
                    id={eachChild.responseDocId}
                    name="option"
                    className="pl-3 h-4 w-4"
                    onChange={() => {
                      setSelectedChoice(eachChild);
                    }}
                  />
                </div>
                <div>
                  <label className="pl-2" htmlFor={eachChild.responseDocId}>
                    {eachChild.child_name}
                  </label>
                </div>
              </div>
            </>
          );
        })}
        <div className="py-6 border-b flex items-center border-[#DBE0E4]">
          <div className="flex items-center">
            <input
              type="radio"
              value="coach"
              id="another"
              name="option"
              className="pl-3 h-4 w-4"
              onChange={() => {
                setSelectedChoice('new');
              }}
            />
          </div>
          <div>
            <label className="pl-2" htmlFor="another">
              Add another child
            </label>
          </div>
        </div>
      </div>
      <div className="lg:max-w-[600px] pb-[68px] w-full px-5 mx-auto flex justify-between items-center">
        <BackButton goBack={goBack} />

        <div className="mt-8 bg-[#FE4254]  px-4 lg:px-0 py-2 flex items-center justify-center  w-24 rounded-full">
          <button
            onClick={() => selectedChoice && submit(selectedChoice)}
            type="submit"
            className="text-white text-base flex items-center"
          >
            <span className="pl-2   font-bold">Next</span>

            <span className="pl-2">
              <svg
                width="14"
                height="10"
                viewBox="0 0 17 12"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M15.5 5.912H1.5"
                  stroke="white"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M10.5 10.912L15.5 5.912"
                  stroke="white"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M10.5 0.912003L15.5 5.912"
                  stroke="white"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </span>
          </button>
        </div>
      </div>
    </div>
  );
};

ChildResponse.propTypes = {
  childs: PropTypes.array,
  submit: PropTypes.func,
  setAddedChildResponse: PropTypes.func,
};

export default ChildResponse;
