import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

const ProgressBarPod = ({ data, setLoading }) => {
  const [progressValue, setProgressValue] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setProgressValue((oldTime) => {
        if (oldTime == 80) {
          clearInterval(interval);
          return 99;
        } else {
          return oldTime + 20;
        }
      });
    }, 1000);
  }, []);

  useEffect(() => {
    if (progressValue == 99) {
      if (data.status) setLoading(false);
    }
  }, [progressValue, data]);

  return (
    <div className="w-full flex flex-col lg:mb-auto lg:w-[468px] max-w[95%] mx-auto">
      <div className="text-center text-lg my-10 mx-2">
        Please wait while we look up suitable pods for you!
      </div>
      <div className="lg:px-0 px-4">
        <div className="h-6 rounded-2xl w-full bg-gray-300">
          <div
            style={{ width: `${progressValue}%` }}
            className={`h-full relative rounded-2xl ${
              progressValue < 70 ? 'bg-blue-600' : 'bg-green-600'
            } `}
          >
            <div className="absolute right-4 text-white font-bold">
              {Math.floor(progressValue)}%
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

ProgressBarPod.propTypes = {
  data: PropTypes.array,
  setLoading: PropTypes.func,
};
export default ProgressBarPod;
