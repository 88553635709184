import PropTypes from 'prop-types';
const ProgressBar = ({ progressPercentage }) => {
  return (
    <div className="w-auto lg:w-full max-w-[1040px] mx-8 mt-4 lg:mx-auto flex relative items-center lg:mt-8">
      <div className="bg-[#DBE0E4] rounded-full h-[14px] w-11/12 lg:w-[98%]"></div>
      <div className="text-[#0E0F10] pl-2 w-1/12 lg:w-[2%]">
        {Math.floor(progressPercentage)}%
      </div>
      <div className="left-0 top-[6px] absolute w-11/12 lg:w-[98%]">
        <div
          className="loader1  rounded-full h-[14px]"
          style={{ width: `${progressPercentage}%` }}
        ></div>
      </div>
    </div>
  );
};

ProgressBar.propTypes = {
  progressPercentage: PropTypes.number,
};

export default ProgressBar;
