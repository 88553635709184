import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import BackButton from '../common/BackButton';
import { deleteCookie } from '../common/helper';

const MultiSelectQuestion = ({
  getRecallAnswer,
  stepsData,
  storeAnswer,
  getDefaultValue,
  sortOptions,
  goBack,
  index,
}) => {
  const [showOther, setShowOther] = useState(false);
  const [otherValues, setOtherValues] = useState({});
  const [checkedArr, setCheckedArr] = useState([]);
  const validations = stepsData.validations ? true : false;
  const styles = stepsData?.styles || {};
  const navigate = useNavigate();
  const restart = () => {
    deleteCookie();
    navigate('/');
  };

  const setCheckboxValue = (e) => {
    const arr = [...checkedArr];
    if (e.target.name != 'other') {
      const option = e.target.value;
      if (e.target.checked) {
        arr.push(option);
      } else {
        const index = arr.indexOf(option);
        if (index > -1) {
          arr.splice(index, 1);
        }
      }
      setShowOther(false);
      setCheckedArr(arr);
    } else {
      setShowOther(true);
    }
  };

  const onSubmit = () => {
    const finalData = {
      step_id: stepsData.id,
      type: stepsData.type,
      step_no: stepsData.step_no,
      variable: stepsData.variable ?? '',
    };
    finalData.answer = checkedArr;
    otherValues[stepsData.id]
      ? (finalData.other_answer = otherValues[stepsData.id])
      : (finalData.other_answer = '');
    setOtherValues({});
    setCheckedArr([]);
    storeAnswer(finalData, stepsData);
  };

  useEffect(() => {
    const defaultData = getDefaultValue(stepsData);
    if (defaultData !== '') {
      const obj = {};
      setCheckedArr(defaultData.answer);
      if (defaultData.other_answer)
        obj[stepsData.id] = defaultData.other_answer;
      setOtherValues(obj);
    }
  }, [stepsData]);
  return (
    <>
      <form>
        <div className="z-[99] lg:mt-8 lg:w-[600px] transition-fade mx-auto relative lg:bg-white p-6 rounded-[24px] lg:shadow-sm">
          <div className="flex items-center">
            {stepsData?.index && (
              <div
                style={{
                  color: stepsData?.styles?.secondary_colour || '#130049',
                  backgroundColor:
                    stepsData?.styles?.primary_colour || '#FBCD41',
                }}
                className="rounded-full w-8 h-8 text-center flex justify-center items-center"
              >
                <span className="text-lg"> {stepsData?.index}</span>
              </div>
            )}
            <div
              dangerouslySetInnerHTML={{
                __html: getRecallAnswer(
                  `${stepsData.heading}${
                    stepsData?.validations?.required
                      ? "<span style='color :#FE4254' >*</span>"
                      : ''
                  }`
                ),
              }}
              className="pl-4 text-[#130049] text-lg"
            ></div>
          </div>
          {stepsData.sub_heading ? (
            <div
              className="mt-4"
              dangerouslySetInnerHTML={{
                __html: getRecallAnswer(stepsData.sub_heading),
              }}
            ></div>
          ) : (
            ''
          )}

          {validations && stepsData.validations.min_selection && (
            <div>
              Must select {stepsData.validations.min_selection} options
              mandatory.
            </div>
          )}
          {Object.keys(sortOptions(stepsData.options)).map((e, i) => {
            return (
              <div
                className="py-6 ml-[9px] border-b border-[#DBE0E4] flex items-center"
                key={btoa(i)}
              >
                <div className="flex items-center">
                  <input
                    type="checkbox"
                    onChange={(e) => setCheckboxValue(e)}
                    id={e}
                    name="multiselect"
                    disabled={
                      validations && stepsData.validations.min_selection
                        ? stepsData.validations.min_selection ==
                          checkedArr.length +
                            (otherValues[stepsData.id] ? 1 : 0)
                          ? !checkedArr.includes(e)
                          : false
                        : false
                    }
                    checked={checkedArr.includes(e)}
                    value={e}
                    className="h-4 w-4 appearance-none border rounded-[3px]"
                  />
                </div>
                <div>
                  <label
                    className={`pl-3 ${
                      validations && stepsData.validations.min_selection
                        ? stepsData.validations.min_selection ==
                            checkedArr.length +
                              (otherValues[stepsData.id] ? 1 : 0) &&
                          !checkedArr.includes(e)
                          ? 'text-zinc-500'
                          : ''
                        : ''
                    } `}
                    htmlFor={e}
                  >
                    {stepsData.options[e]}
                  </label>
                </div>
                <br />
              </div>
            );
          })}
          {!showOther ? (
            <div className="py-4 ml-[9px] border-b  border-[#DBE0E4] flex items-center last:border-b-0">
              <div className="flex items-center">
                <input
                  onChange={(e) => setCheckboxValue(e)}
                  checked={otherValues[stepsData.id] ? true : false}
                  type="checkbox"
                  disabled={
                    validations && stepsData.validations.min_selection
                      ? stepsData.validations.min_selection ==
                        checkedArr.length + (otherValues[stepsData.id] ? 1 : 0)
                        ? !otherValues[stepsData.id]
                        : false
                      : false
                  }
                  name="other"
                  value={otherValues[stepsData.id]}
                  id="multiselect"
                  className="h-4 w-4 appearance-none border rounded-[3px]"
                />
              </div>
              <div>
                <label
                  className={`pl-2 ${
                    validations && stepsData.validations.min_selection
                      ? stepsData.validations.min_selection ==
                          checkedArr.length +
                            (otherValues[stepsData.id] ? 1 : 0) &&
                        !otherValues[stepsData.id]
                        ? 'text-zinc-500'
                        : ''
                      : ''
                  } `}
                  htmlFor="multiselect"
                >
                  {otherValues[stepsData.id]
                    ? otherValues[stepsData.id]
                    : 'Other'}
                </label>
              </div>
              {otherValues[stepsData.id] && (
                <span
                  className="ml-2 cursor-pointer"
                  onClick={() => {
                    setShowOther(true);
                  }}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth={1.5}
                    stroke="currentColor"
                    className="h-6 w-4"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M16.862 4.487l1.687-1.688a1.875 1.875 0 112.652 2.652L6.832 19.82a4.5 4.5 0 01-1.897 1.13l-2.685.8.8-2.685a4.5 4.5 0 011.13-1.897L16.863 4.487zm0 0L19.5 7.125"
                    />
                  </svg>
                </span>
              )}
            </div>
          ) : (
            <div className="flex justify-between">
              <div className="mt-8 w-3/4">
                <input
                  className="w-full border rounded-full p-3 outline-none"
                  placeholder="Other"
                  onChange={(e) => {
                    const obj = { ...otherValues };
                    obj[stepsData.id] = e.target.value;
                    setOtherValues(obj);
                  }}
                  value={
                    otherValues[stepsData.id] ? otherValues[stepsData.id] : ''
                  }
                />
              </div>
              <div className="mt-8">
                <button
                  onClick={() => {
                    setShowOther(false);
                  }}
                  className="bg-[#FE4254] px-8 py-4  flex items-center w-20 rounded-full text-white text-base"
                >
                  Ok
                </button>
              </div>
            </div>
          )}
          {stepsData?.index && index !== 0 && (
            <div className="text-xs mt-6 underline font-normal ">
              <span onClick={restart} className="cursor-pointer">
                Restart Assessment
              </span>
            </div>
          )}
        </div>
        <div className="max-w-[600px] px-6 lg:px-0 pb-8 mx-auto flex justify-between items-center ">
          {(index !== 0 || !stepsData.index) && <BackButton goBack={goBack} />}

          <div
            className={`mt-8 ${
              !index && 'mx-auto'
            }    px-4 py-2 flex items-center w-24 rounded-full    ${
              validations && stepsData.validations.min_selection
                ? stepsData.validations.min_selection ==
                  checkedArr.length + (otherValues[stepsData.id] ? 1 : 0)
                  ? ` bg-[${styles?.btn_bg_colour || '#FE4254'}]`
                  : 'bg-gray-300'
                : stepsData?.validations?.required
                ? checkedArr.length + (otherValues[stepsData.id] ? 1 : 0)
                  ? ` bg-[${styles?.btn_bg_colour || '#FE4254'}]`
                  : 'bg-gray-300'
                : ` bg-[${styles?.btn_bg_colour || '#FE4254'}]`
            }`}
          >
            <button
              type="submit"
              disabled={
                validations && stepsData.validations.min_selection
                  ? stepsData.validations.min_selection ==
                    checkedArr.length + (otherValues[stepsData.id] ? 1 : 0)
                    ? false
                    : true
                  : stepsData?.validations?.required
                  ? checkedArr.length + (otherValues[stepsData.id] ? 1 : 0)
                    ? false
                    : true
                  : false
              }
              onClick={onSubmit}
              className={` text-white text-base flex items-center `}
            >
              <span className="pl-2  font-normal">
                {styles?.btn_content || 'Next'}
              </span>

              <span className="pl-2">
                <svg
                  width="14"
                  height="10"
                  viewBox="0 0 17 12"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M15.5 5.912H1.5"
                    stroke="white"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M10.5 10.912L15.5 5.912"
                    stroke="white"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M10.5 0.912003L15.5 5.912"
                    stroke="white"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </span>
            </button>
          </div>
        </div>
      </form>
    </>
  );
};

MultiSelectQuestion.propTypes = {
  getRecallAnswer: PropTypes.func,
  stepsData: PropTypes.object,
  storeAnswer: PropTypes.func,
  getDefaultValue: PropTypes.func,
  sortOptions: PropTypes.func,
  goBack: PropTypes.func,
  index: PropTypes.number,
};

export default MultiSelectQuestion;
