import { useContext, useEffect, useRef, useState } from 'react';
import log from '../core/log';
import LoadingSpinner from '../common/Loading';
import ProgressBar from './ProgressBar';
import FreeText from '../Types/FreeText';
import MultiChoiceQuestion from '../Types/MultiChoice';
import Number from '../Types/Number';
import MultiSelectQuestion from '../Types/MultiSelect';
import Blurb from '../Types/Blurb';
import ReactGA from 'react-ga';
import Scale from '../Types/Scale';
import Email from '../Types/Email';
import axios from 'axios';
import TagManager from 'react-gtm-module';
import { useLocation, useNavigate } from 'react-router';
import Topic from './Topic';
import EndPage from '../Types/Static/EndPage';
import { retriveCookie } from '../common/helper';
import { motion } from 'framer-motion';
import { Link } from 'react-router-dom';
import { CoreContext } from '../context/CoreContext';

const TRACKING_ID = process.env.REACT_APP_GA_TRACKING_ID;
ReactGA.initialize(TRACKING_ID);

const tagManagerArgs = {
  gtmId: process.env.REACT_APP_GA_GTM_ID,
};
TagManager.initialize(tagManagerArgs);
const Assessment = () => {
  const { data, coreLoading } = useContext(CoreContext);
  const location = useLocation();
  const { form } = location.state || '';
  const { step_no } = location.state || '';
  const [indexQueue, setIndexQueue] = useState([0]);
  const [stepsData, setStepsData] = useState([]);
  const noProgressBar = ['pod', 'payment', 'email', 'topic'];
  const topLogo = ['email', 'topic', 'static'];
  const slowTransition = ['multi_choice_question', 'blurb', 'scale'];
  const [progressBar, setProgressBar] = useState({
    value: null,
    percent: null,
  });
  const [formData, setFormData] = useState({});
  const [loading, setLoading] = useState(true);
  const [startAssesment, setStartAssessment] = useState(false);
  const [index, setIndex] = useState(0);
  const [selectedChoice, setSelectedChoice] = useState({
    id: null,
    value: null,
  });
  const navigate = useNavigate();
  const [response, setResponse] = useState([]);
  const emailRef = useRef();
  const [answerObj, setAnswerObj] = useState({});
  const [customer, setCustomer] = useState('');
  const cookies = retriveCookie(form);
  const answerCookie = retriveCookie(`${form}-answer`);
  const [transition, setTransition] = useState(false);
  const customerData = cookies?.customer;

  const updateCookies = (customerData) => {
    const obj = {
      formName: form,
      customer: customerData ? customerData : customer,
      variable: answerObj,
    };
    document.cookie = `${form}=` + JSON.stringify(obj);
  };

  useEffect(() => {
    if (Object.keys(answerObj).length) {
      updateCookies();
    }
  }, [answerObj]);

  useEffect(() => {
    if (response.length) {
      document.cookie =
        `${form}-answer=` + JSON.stringify({ answer: response });
    }
  }, [response]);

  const sortOptions = (obj) => {
    const finalSorted = Object.keys(obj)
      .sort((a, b) => a - b)
      .reduce((accumulator, key) => {
        accumulator[key] = obj[key];

        return accumulator;
      }, {});
    return finalSorted;
  };
  const getRemainingSteps = async (step_no) => {
    const currStepNo = +step_no;

    try {
      let data = {
        form_name: form,
        step_no: currStepNo,
      };
      let config = {
        method: 'post',
        url: process.env.REACT_APP_GET_TAPOUTS_STEPS,
        headers: {},
        data: data,
      };
      const result = await axios(config);

      setStepsData(result?.data?.remainingSteps);
      setLoading(false);
    } catch (err) {
      log('Error while getting data', err.message);
    }
  };

  useEffect(() => {
    if (stepsData.length) {
      let data = [...stepsData];
      if (data[index].type == 'payment' && customerData?.subscription) {
        data.splice(index, 1);
        setStepsData(data);
      }
    }
  }, [stepsData, index]);

  const vaidateStepCondition = (
    type,
    operand,
    answer,
    choice,
    steps,
    target
  ) => {
    if (type != 'multi_select_question') {
      switch (operand) {
        case '==':
          if (answer.toLowerCase() == choice.toLowerCase())
            return steps[target].index;
          break;
        case '!==':
          if (answer.toLowerCase() !== choice.toLowerCase())
            return steps[target].index;
          break;
        case '>':
          if (+answer > +choice) return steps[target].index;
          break;
        case '<':
          if (+answer < +choice) return steps[target].index;
          break;
        case '=>':
          if (+answer >= +choice) return steps[target].index;
          break;
        case '<=':
          if (+answer <= +choice) return steps[target].index;
          break;
      }
    } else if (type == 'multi_select_question') {
      return steps[target].index;
    }
  };

  const getCondition = (condition, withOperand, answer, obj) => {
    const conditionMatches = [];
    for (let cond of condition) {
      if (!withOperand.includes(obj.type) && cond?.answer == answer) {
        conditionMatches.push(cond);
      }

      if (withOperand.includes(obj.type)) {
        switch (cond.operand) {
          case '>':
            if (+answer > +cond.answer) conditionMatches.push(cond);
            break;
          case '<':
            if (+answer < +cond.answer) conditionMatches.push(cond);
            break;
          case '=>':
            if (+answer >= +cond.answer) conditionMatches.push(cond);
            break;
          case '<=':
            if (+answer <= +cond.answer) conditionMatches.push(cond);
            break;
          case '==':
            if (answer == cond.answer) conditionMatches.push(cond);
            break;
          case '!==':
            if (answer !== cond.answer) conditionMatches.push(cond);
            break;
        }
      }
    }
    return conditionMatches;
  };

  const getNextStep = (obj, type, answer) => {
    let nextStep = -1;
    const condition = obj.conditions;
    if (!condition && !condition.length) return nextStep;
    const steps = {};
    const responseObj = {};
    const withOperand = [
      'number',
      'scale',
      'free_text',
      'multi_choice_question',
      'blurb',
    ];
    let multipleConditionArr = [true];
    stepsData.forEach((e, i) => (steps[e.id] = { ...e, index: i }));
    response.forEach((e) => {
      if (e.type !== 'topic') {
        responseObj[e.step_id] = e;
      } else {
        e.answer.forEach((e1) => (responseObj[e1.step_id] = e1));
      }
    });
    let defaultTarget = condition.find((e) => e.id == 'default');

    const actualCondition = [
      ...getCondition(condition, withOperand, answer, obj),
    ];

    if (!actualCondition.length) {
      if (defaultTarget) nextStep = steps[defaultTarget.target].index;
      return nextStep;
    }
    for (let cond of actualCondition) {
      for (let e of cond.condition) {
        if (cond.type == 'single') {
          const operand = e.operand;
          const choice = cond.answer;
          const target = e.target;

          const index = vaidateStepCondition(
            type,
            operand,
            answer,
            choice,
            steps,
            target
          );

          if (index) return index;
        } else if (cond.type == 'multiple') {
          const index = vaidateStepCondition(
            e.type,
            e.operand,
            responseObj[e.step_id]?.answer,
            e.answer,
            steps,
            cond.target
          );

          multipleConditionArr.push(e.connector);
          index
            ? multipleConditionArr.push(true)
            : multipleConditionArr.push(false);
        }
      }
      if (cond.type == 'multiple') {
        const conditionTrue = eval(multipleConditionArr.join(' '));
        if (conditionTrue) {
          console.log(multipleConditionArr);
          nextStep = steps[cond.target].index;
          return nextStep;
        } else {
          multipleConditionArr = [true];
        }
      }
    }

    if (defaultTarget) nextStep = steps[defaultTarget.target].index;

    return nextStep;
  };

  const goBack = () => {
    if (index == 0) {
      navigate(-1);
      return;
    }
    const queue = [...indexQueue];

    let previousIndex = queue[queue.length - 2];
    if (stepsData[queue[queue.length - 2]]?.type == 'topic') {
      previousIndex = queue[queue.length - 3];
      queue.pop();
    }
    queue.pop();
    progressBarCalc(previousIndex);
    setIndex(previousIndex);
    setIndexQueue(queue);
    setTransition(true);
  };

  useEffect(() => {
    if (!stepsData.length) return;
    let value = stepsData.length;
    let percent = 100 / value;
    if (step_no && customerData?.overall_steps) {
      console.log(
        100 / customerData?.overall_steps,
        customerData.overall_steps - stepsData.length
      );
      percent =
        (100 / customerData?.overall_steps) *
        (customerData.overall_steps - stepsData.length + 1);
      value = customerData.overall_steps;
    }
    setProgressBar({
      value: value,
      percent: percent,
    });
  }, [stepsData]);

  useEffect(() => {
    if (!form) navigate('/');
    if (!response.length && cookies) {
      const answer = answerCookie?.answer;
      const customerData = cookies?.customer || '';
      setResponse(answer || []);
      setCustomer(customerData || {});
    }
    if (step_no) {
      if (!cookies) navigate('/');
      setFormData(cookies?.formData || {});
      getRemainingSteps(step_no);
      setAnswerObj(cookies?.variable || {});
      setStartAssessment(true);
    } else {
      if (!coreLoading && data.stepsData.length) {
        setFormData(data.formData);
        setStepsData(data.stepsData);
        setStartAssessment(true);
        setLoading(false);
      }
    }
  }, [location, data]);

  useEffect(() => {
    if (stepsData.length && stepsData.length > index && startAssesment) {
      GooogleAnalytics(stepsData[index].url_query);
      window.dataLayer.push({
        event: 'pageview',
        page: {
          title: stepsData[index].url_query,
        },
      });
    }
  }, [index, startAssesment]);

  const GooogleAnalytics = (variable) => {
    let pageHit = `/${variable ? variable : ''} `;
    ReactGA.pageview(pageHit);
  };

  const storeAnswer = async (obj, stepData) => {
    let data = { ...obj };
    let nextIndex = index + 1;
    const typeForCond = [
      'free_text',
      'number',
      'multi_choice_question',
      'scale',
      'blurb',
    ];
    const answerRefObj = { ...answerObj };
    setTransition(false);

    if (typeForCond.includes(obj.type)) {
      let value = '';

      if (
        obj.type == 'multi_choice_question' ||
        obj.type == 'scale' ||
        obj.type == 'blurb'
      ) {
        value = selectedChoice.value;
      } else {
        value = data.answer;
      }

      if (obj.variable) {
        answerRefObj[`<${formData.variable[obj.variable]}>`] = value;
        setAnswerObj(answerRefObj);
      }
      if (
        obj.type == 'multi_choice_question' ||
        obj.type == 'scale' ||
        obj.type == 'blurb'
      ) {
        setSelectedChoice({
          id: null,
          value: null,
        });
      }
      await storeResponse(data, data.step_id);
    } else if (obj.type == 'multi_select_question') {
      if (obj?.variable) {
        const value = obj.answer.length
          ? stepData.options[obj.answer[0]]
          : obj?.other_answer
          ? obj?.other_answer
          : '';
        answerRefObj[`<${formData.variable[obj.variable]}>`] = value;
        setAnswerObj(answerRefObj);
      }
      await storeResponse(data, data.step_id);
    }

    if (
      Object.prototype.hasOwnProperty.call(stepData, 'conditions') &&
      stepData.conditions.length !== 0
    ) {
      const nextStep =
        stepData.type !== 'scale'
          ? getNextStep(stepData, stepData.type, data.answer)
          : getNextStep(
              stepData,
              stepData.type,
              stepData.options[data.answer].value
            );
      if (nextStep !== -1 && nextIndex) nextIndex = nextStep;
    }
    if (slowTransition.includes(stepsData[index].type)) {
      setTimeout(() => {
        navigateToNextStep(nextIndex);
        if (stepsData[index].type !== 'topic') {
          progressBarCalc(nextIndex);
        }
      }, 500);
    } else {
      navigateToNextStep(nextIndex);
      if (stepsData[index].type !== 'topic') {
        progressBarCalc(nextIndex);
      }
    }
  };

  const navigateToNextStep = (nextIndex) => {
    const urlParam = ['pod', 'payment'];
    const nextStep = stepsData[nextIndex];

    if (urlParam.includes(nextStep.type)) {
      if (nextStep.type == 'pod') {
        navigate('/podlist', {
          state: {
            form: form,
            step: { ...nextStep, index: nextIndex },
          },
        });
      } else if (nextStep.type == 'payment') {
        if (!customerData?.subscription) {
          navigate('/payment', {
            state: {
              form: form,
              step: { ...nextStep, index: nextIndex },
            },
          });
        } else {
          setIndex(nextIndex + 1);
          setIndexQueue((current) => [...current, nextIndex + 1]);
        }
      }
    } else {
      setIndex(nextIndex);
      setIndexQueue((current) => [...current, nextIndex]);
    }
  };

  const storeResponse = async (data, id) => {
    if (step_no) data.status = 'updated';
    const responseData = [...response];
    const addedStep = [...stepsData];
    const findStep = addedStep.findIndex((e) => e.id == data.step_id);
    if (Object.prototype.hasOwnProperty.call(addedStep[findStep], 'topic_id')) {
      const topicIndex = responseData.findIndex(
        (e) => e.step_id == addedStep[findStep].topic_id
      );
      const topicStepIndex = addedStep.findIndex(
        (e) => e.id == addedStep[findStep].topic_id
      );
      if (topicIndex !== -1) {
        const subStepAnswerIndex = responseData[topicIndex].answer.findIndex(
          (subStep) => subStep.step_id == addedStep[findStep].id
        );
        subStepAnswerIndex == -1
          ? responseData[topicIndex].answer.push(data)
          : (responseData[topicIndex].answer[subStepAnswerIndex] = data);
      } else {
        const obj = { ...addedStep[topicStepIndex] };
        obj.answer = [data];
        obj.step_id = addedStep[topicStepIndex].id;
        delete obj.id;
        responseData.push(obj);
      }
    } else {
      const idIndex = responseData.findIndex((e) => e.step_id == id);

      if (idIndex !== -1) {
        responseData[idIndex] = data;
      } else {
        responseData.push(data);
      }
    }
    setResponse(responseData);
  };

  const progressBarCalc = (ind) => {
    let stepIndex = ind;
    let value = progressBar.value;
    if (customerData?.overall_steps) {
      let last_step = customerData.overall_steps - stepsData.length;
      stepIndex = last_step + ind;
      value = customerData?.overall_steps;
    }
    const percent = (100 / value) * (stepIndex + 1);
    setProgressBar({
      value: progressBar.value,
      percent: percent,
    });
  };

  const StoreEmail = async (mailId, step, setError) => {
    setLoading(true);

    const emailId = mailId.trim();
    const podIndex = stepsData.findIndex((e) => e.type == 'pod');
    const responseCopy = response.filter((e) => e.step_no < step.step_no);
    setResponse(responseCopy);

    let tempObj = {
      status: 'review',
      subscription_status: 'not-paid',
      pod_step: { ...stepsData[podIndex], index: podIndex },
      overall_steps: progressBar.value,
    };

    try {
      let data = {
        formData: tempObj,
        formName: formData.form_name,
        variable: formData.variable,
        email: emailId,
        steps: responseCopy,
      };

      let config = {
        method: 'post',
        url: process.env.REACT_APP_TAPOUTS_FORM_RESPONSE,
        headers: {
          'Content-Type': 'application/json',
        },
        data: data,
      };
      axios(config)
        .then(async (result) => {
          if (result?.data?.message) {
            emailRef.current.value = '';
            setError('200');
            setLoading(false);
          } else {
            let stateData = {
              ...result.data.data,
              email: emailId,
              responseDocId: result.data.responseDocId,
            };
            updateCookies(stateData);
            if (Object.prototype.hasOwnProperty.call(step, 'conditions')) {
              const { target } = step.conditions.find(
                (e) => e.type == 'default_target'
              );
              const nextIndex = stepsData.findIndex((e) => e.id == target);
              navigateToNextStep(nextIndex);
            } else {
              navigateToNextStep(index + 1);
            }

            setLoading(false);
          }
        })
        .catch((error) => {
          console.log(error);
          setError(error.response.data.message);
          setLoading(false);
        });
    } catch (err) {
      log('Error while validating mail id', err);
      setLoading(false);
    }
  };

  const getRecallAnswer = (string, blurb) => {
    if (!string) return '';
    let str = string;
    Object.keys(answerObj).forEach((e) => {
      const variable = !blurb ? e : e.replace(`<`, `$/`).replace('>', '/$');
      if (str.includes(variable)) {
        let answer = answerObj[e];
        answer = answer.charAt(0).toUpperCase() + answer.slice(1);
        str = str.replaceAll(variable, `<strong>${answer}</strong>`);
      }
    });
    return str;
  };

  const getDefaultValue = (parentStep) => {
    let final = '';
    let res = [...response];

    if (Object.prototype.hasOwnProperty.call(parentStep, 'topic_id')) {
      const topicIndex = response.findIndex(
        (e) => e.step_id == parentStep.topic_id
      );
      if (topicIndex !== -1) res = response[topicIndex].answer;
    }

    if (response.length) {
      for (const step of res) {
        if (parentStep.id == step.step_id) {
          if (parentStep.type == 'multi_select_question') {
            final = {
              answer: step.answer,
              other_answer: step.other_answer ? step.other_answer : '',
            };
          } else {
            final = step.answer;
          }
        }
      }
    }

    return final;
  };

  const checkNextIndex = (ind) => {
    let final = true;
    if (stepsData.length == ind + 1) {
      final = false;
    }
    return final;
  };

  return (
    <div
      style={{
        backgroundImage: `url(./images${
          stepsData[index]?.type !== 'email'
            ? stepsData[index]?.styles?.background_image || ''
            : ''
        })`,
      }}
      className={`bg-cover flex flex-col justify-start lg:justify-between bg-no-repeat min-h-screen bg-center-bottom relative`}
    >
      {loading && <LoadingSpinner />}
      {startAssesment && stepsData.length !== 0 && stepsData.length > index && (
        <>
          {!topLogo.includes(stepsData[index].type) && (
            <div className="relative">
              <div className="flex justify-center items-center py-10">
                <Link to="/">
                  <img
                    src="./images/tapouts-logo.png"
                    width="138px"
                    height="40px"
                  />
                </Link>
              </div>
            </div>
          )}
          {Object.prototype.hasOwnProperty.call(
            stepsData[index],
            'topic_id'
          ) && (
            <div
              style={{
                color: stepsData[index]?.styles?.secondary_colour || '#130049',
                backgroundColor:
                  stepsData[index]?.styles?.primary_colour || '#FBCD41',
              }}
              className="mt-4 px-4 py-3 w-auto mx-6 lg:w-[475px] text-center  lg:mt-10 lg:mx-auto rounded-full"
            >
              <span
                dangerouslySetInnerHTML={{
                  __html: getRecallAnswer(
                    stepsData[stepsData[index].parentIndex].heading
                  )
                    .replace('<strong>', '')
                    .replace('</strong>', ''),
                }}
              ></span>
            </div>
          )}

          {!noProgressBar.includes(stepsData[index].type) &&
            checkNextIndex(index) && (
              <ProgressBar
                progressPercentage={
                  progressBar.percent ? progressBar.percent : 0
                }
              />
            )}
          <motion.div
            variants={{
              initial: { x: !transition ? '100vw' : '-100vw', opacity: 0 },
              animate: { x: 0, opacity: 1 },
            }}
            initial="initial"
            animate="animate"
            key={index}
            transition={{ duration: 0.3, delay: 0.1 }}
          >
            {stepsData[index].type == 'topic' && (
              <Topic
                step={stepsData[index]}
                index={index}
                setIndexQueue={setIndexQueue}
                setIndex={setIndex}
                getRecallAnswer={getRecallAnswer}
              />
            )}

            {stepsData[index].type === 'free_text' && (
              <FreeText
                getRecallAnswer={getRecallAnswer}
                stepsData={stepsData[index]}
                getDefaultValue={getDefaultValue}
                storeAnswer={storeAnswer}
                index={index}
                goBack={goBack}
              />
            )}
            {stepsData[index].type === 'number' && (
              <Number
                getRecallAnswer={getRecallAnswer}
                stepsData={stepsData[index]}
                getDefaultValue={getDefaultValue}
                storeAnswer={storeAnswer}
                index={index}
                goBack={goBack}
              />
            )}
            {stepsData[index].type === 'multi_choice_question' && (
              <MultiChoiceQuestion
                getRecallAnswer={getRecallAnswer}
                stepsData={stepsData[index]}
                setSelectedChoice={setSelectedChoice}
                selectedChoice={selectedChoice}
                storeAnswer={storeAnswer}
                getDefaultValue={getDefaultValue}
                sortOptions={sortOptions}
                index={index}
                goBack={goBack}
              />
            )}
            {stepsData[index].type === 'scale' && (
              <Scale
                getRecallAnswer={getRecallAnswer}
                stepsData={stepsData[index]}
                setSelectedChoice={setSelectedChoice}
                selectedChoice={selectedChoice}
                storeAnswer={storeAnswer}
                getDefaultValue={getDefaultValue}
                sortOptions={sortOptions}
                index={index}
                goBack={goBack}
              />
            )}
            {stepsData[index].type === 'multi_select_question' && (
              <MultiSelectQuestion
                getRecallAnswer={getRecallAnswer}
                stepsData={stepsData[index]}
                storeAnswer={storeAnswer}
                getDefaultValue={getDefaultValue}
                sortOptions={sortOptions}
                index={index}
                goBack={goBack}
              />
            )}
            {stepsData[index].type === 'blurb' && (
              <Blurb
                setLoading={setLoading}
                stepsData={stepsData[index]}
                index={index}
                checkNextIndex={checkNextIndex}
                setSelectedChoice={setSelectedChoice}
                storeAnswer={storeAnswer}
                getRecallAnswer={getRecallAnswer}
                getDefaultValue={getDefaultValue}
                goBack={goBack}
                selectedChoice={selectedChoice}
              />
            )}
          </motion.div>
          {stepsData[index].type === 'email' && (
            <Email
              step={stepsData[index]}
              emailRef={emailRef}
              StoreEmail={StoreEmail}
              answerObj={answerObj}
              formData={formData}
            />
          )}
          {stepsData[index].type === 'static' && (
            <>
              {stepsData[index]?.extra_variable.page == 'success-page' && (
                <EndPage getRecallAnswer={getRecallAnswer} formName={form} />
              )}
            </>
          )}
        </>
      )}
    </div>
  );
};

export default Assessment;
