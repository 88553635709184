import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import log from '../core/log';
import axios from 'axios';
import BackButton from '../common/BackButton';

const Blurb = ({
  stepsData,
  setLoading,
  checkNextIndex,
  index,
  storeAnswer,
  setSelectedChoice,
  getRecallAnswer,
  goBack,
  getDefaultValue,
  selectedChoice,
}) => {
  const [blurbData, setBlurbData] = useState({});
  const defaultData = getDefaultValue(stepsData);
  const styles = stepsData?.styles || {};

  const getBlurbData = async () => {
    setLoading(true);
    try {
      let data = {
        blurb_id: stepsData.blurb_id,
      };

      let config = {
        method: 'post',
        url: process.env.REACT_APP_GET_BLURB_DETAILS,
        headers: {
          'Content-Type': 'application/json',
        },
        data: data,
      };

      let response = await axios(config);
      if (response.data.message) {
        console.log(response.data.message);
        setLoading(false);
      } else {
        setBlurbData(response.data.data);
        setLoading(false);
      }
    } catch (err) {
      log('Error while fetching blurb', err);
      setLoading(false);
    }
  };

  const onsubmit = (value) => {
    const finalData = {
      step_id: stepsData.id,
      type: stepsData.type,
      step_no: stepsData.step_no,
      variable: stepsData.variable ?? '',
      answer: value,
    };
    storeAnswer(finalData, { ...stepsData, options: blurbData.buttons });
  };

  useEffect(() => {
    getBlurbData();
  }, [stepsData]);

  useEffect(() => {
    if (Object.keys(blurbData).length !== 0 && defaultData) {
      setSelectedChoice({
        id: defaultData,
        value: blurbData?.buttons[defaultData].value,
      });
    }
  }, [blurbData, defaultData]);
  return (
    <div className="relative mt-6 flex flex-col min-h-[65vh] justify-between">
      {Object.keys(blurbData).length !== 0 && (
        <>
          <div className="flex flex-col justify-center mx-6 lg:mx-auto items-center p-8 w-auto bg-white lg:max-w-[600px] rounded-[24px]">
            {blurbData?.image_url && (
              <div className="h-36 w-36 rounded-full bg-green-600 border-white relative mb-4 -mt-16 ml-6">
                <div className="w-full h-full relative rounded-full overflow-hidden">
                  <img
                    className="w-full h-full absolute rounded-full inset-0"
                    src={`./images/${blurbData.image_url}`}
                    alt="coach-avatar"
                  />
                </div>
              </div>
            )}
            <div
              dangerouslySetInnerHTML={{
                __html: getRecallAnswer(blurbData.description, 'blurb'),
              }}
              className="text-2xl font-black text-[#130049] bookmania-bold"
            ></div>
            {checkNextIndex(index) && (
              <div className="flex justify-between w-full gap-2 text-center mental-health">
                {Object.keys(blurbData.buttons).map((e) => {
                  return (
                    <button
                      style={{
                        backgroundColor: blurbData.buttons[e]?.bg_colour
                          ? blurbData.buttons[e]?.bg_colour
                          : '#b4b4b4',
                        color: blurbData.buttons[e]?.text_colour
                          ? blurbData.buttons[e]?.text_colour
                          : '#fff',
                      }}
                      key={e}
                      onClick={() => {
                        setSelectedChoice({
                          id: e,
                          value: blurbData.buttons[e].value,
                        });
                        onsubmit(e);
                      }}
                      className={`flex-1 rounded-[16px] text-2xl font-black h-[80px] px-6 py-3 mt-3 block transition-all duration-150 ease-linear shadow outline-none hover:bg-yellow-500 hover:shadow-lg focus:outline-none   ${
                        getDefaultValue(stepsData) == e
                          ? ' border-4 border-[#130049]'
                          : ''
                      }`}
                    >
                      {blurbData.buttons[e].heading}
                    </button>
                  );
                })}
              </div>
            )}
          </div>
          <div className="lg:max-w-[600px] text-lg pb-[64px] w-full px-5 mx-auto flex justify-between items-center">
            {(index !== 0 || !stepsData.index) && (
              <BackButton goBack={goBack} />
            )}
            {defaultData && (
              <div
                className={`mt-8 bg-[${
                  styles?.btn_bg_colour || '#FE4254'
                }]   px-4 py-2 flex items-center  ${
                  !index && 'mx-auto'
                } w-24 rounded-full`}
              >
                <button
                  type="submit"
                  onClick={() => onsubmit(selectedChoice.id)}
                  className="text-white text-base flex items-center"
                >
                  <span className="pl-2">{styles?.btn_content || 'Next'}</span>

                  <span className="pl-2">
                    <svg
                      width="14"
                      height="10"
                      viewBox="0 0 17 12"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M15.5 5.912H1.5"
                        stroke="white"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M10.5 10.912L15.5 5.912"
                        stroke="white"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M10.5 0.912003L15.5 5.912"
                        stroke="white"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                  </span>
                </button>
              </div>
            )}
          </div>
        </>
      )}
    </div>
  );
};
Blurb.propTypes = {
  stepsData: PropTypes.object,
  setLoading: PropTypes.func,
  checkNextIndex: PropTypes.func,
  index: PropTypes.number,
  storeAnswer: PropTypes.func,
  setSelectedChoice: PropTypes.func,
  getRecallAnswer: PropTypes.func,
  goBack: PropTypes.func,
  getDefaultValue: PropTypes.func,
  selectedChoice: PropTypes.object,
};

export default Blurb;
