import { useEffect, useRef, useState } from 'react';
import Coach from './Coach';
import log from '../core/log';
import axios from 'axios';
import { useLocation, useNavigate } from 'react-router';
import ProgressBar from './ProgressBar';
import BackButton from '../common/BackButton';
import { retriveCookie } from '../common/helper';
import ProgressBarPod from '../common/ProgressBarPod';
import LoadingSpinner from '../common/Loading';
import { Link } from 'react-router-dom';

const PodList = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { form } = location.state || {};
  const { step } = location.state || {};
  const otherRef = useRef();
  const [loading, setLoading] = useState(false);
  const [mainLoading, setMainLoading] = useState(true);
  const [coach, setCoach] = useState({});
  const [showCoach, setShowCoach] = useState();
  const [error, setError] = useState('');
  const days = {
    MON: 'Mondays',
    TUE: 'Tuesdays',
    WED: 'Wednesdays',
    THU: 'Thursdays',
    FRI: 'Fridays',
    SAT: 'Saturdays',
    SUN: 'Sundays',
  };
  const [selectedChoice, setSelectedChoice] = useState({
    id: null,
    value: null,
  });
  const cookies = retriveCookie(form);
  const customer = cookies?.customer;
  const variable = cookies?.variable;
  const step_no = step?.step_no;
  const progressBar = customer?.overall_steps
    ? (100 / customer?.overall_steps) * step?.index
    : 0;
  const [podList, setPodList] = useState({ pods: [], status: false });

  const moveToNextStep = () => {
    if (customer?.subscription) {
      navigate('/podsuccess', {
        state: {
          form: form,
        },
      });
    } else {
      navigate('/enrollment', {
        state: {
          step_no: step_no,
          form: form,
        },
      });
    }
  };

  const allignPods = (pods) => {
    const sortedPods = [];
    const age = +customer.child_age;

    pods.forEach((e) => {
      const ageGroupSplit = e.age_group.split('-');
      const minAge = +ageGroupSplit[0];
      const maxAge = +ageGroupSplit[1];
      if (minAge <= age && maxAge >= age) {
        sortedPods.push(e);
      }
    });
    setPodList({ pods: sortedPods, status: true });
  };

  const getPodDetailsByAge = async () => {
    setMainLoading(true);
    try {
      let config = {
        method: 'get',
        url: `${process.env.REACT_APP_POD_DETAILS}`,
        headers: {},
      };
      let response = await axios(config);
      let pods = response.data.pods;
      allignPods(pods);
    } catch (err) {
      log('Error while fetching pods', err);
      setMainLoading(false);
    }
  };

  useEffect(() => {
    if (!form || !step || !cookies) navigate('/');
    getPodDetailsByAge();
  }, [location]);

  const getPod = async (pod) => {
    setLoading(true);
    try {
      const coachDetails = await fetch(
        process.env.REACT_APP_GET_COACH_DETAILS,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            coach_id: pod.coach_id,
          }),
        }
      );
      const coachData = await coachDetails.json();

      setCoach(coachData?.data ? coachData?.data : {});
    } catch (err) {
      setLoading(false);
      log('Error while fetching coach detail ', err);
    }

    let obj = {
      podDocId: pod.id,
      pod_selection: true,
      other_pod: '',
    };

    let data = {
      formData: obj,
      responseDocId: customer.responseDocId,
      formName: form,
    };

    let config = {
      method: 'post',
      url: process.env.REACT_APP_TAPOUTS_FORM_RESPONSE,
      headers: {
        'Content-Type': 'application/json',
      },
      data: data,
    };

    axios(config)
      .then(function (response) {
        if (response.data.success) {
          const customerExist = {
            ...cookies.customer,
            ...response.data.formData,
          };

          document.cookie =
            `${form}=` +
            JSON.stringify({ ...cookies, ...{ customer: customerExist } });
          setShowCoach(pod);
          setLoading(false);
        } else {
          setLoading(false);
          return;
        }
      })
      .catch(function (error) {
        console.log(error);
        setLoading(false);
        return;
      });
  };

  const getRecallAnswer = (string) => {
    if (!string || !variable) return string;
    let str = string;
    Object.keys(variable).forEach((e) => {
      if (str.includes(e)) {
        let answer = variable[e];
        answer = answer.charAt(0).toUpperCase() + answer.slice(1);
        str = str.replaceAll(e, answer);
      }
    });
    return str;
  };

  useEffect(() => {
    if (customer?.podDocId && podList.pods.length) {
      const pod = podList.pods.find((e) => e.id == customer?.podDocId);
      if (pod) {
        setSelectedChoice({
          id: customer?.podDocId,
          value: pod,
        });
      }
    }
  }, [location, podList]);

  const goBack = () => {
    navigate('/enrollment', {
      state: {
        form: form,
      },
    });
  };

  const getCapitalize = (arr) => {
    if (!arr.length) return '';
    let final = [];
    arr.forEach((e) => final.push(days[e]));
    return final.join(',');
  };

  const getPodDetails = (pod) => {
    setSelectedChoice({ id: pod.id, value: pod });
    setTimeout(() => {
      getPod(pod);
    }, 500);
  };

  const otherPods = async (value) => {
    if (!value) {
      return setError('Please enter other pod time');
    }

    let obj = {
      podDocId: '',
      pod_selection: false,
      other_pod: value,
    };

    let data = {
      formData: obj,
      responseDocId: customer.responseDocId,
      formName: form,
    };

    let config = {
      method: 'post',
      url: process.env.REACT_APP_TAPOUTS_FORM_RESPONSE,
      headers: {
        'Content-Type': 'application/json',
      },
      data: data,
    };

    axios(config)
      .then(function (response) {
        if (response.data.success) {
          const customerExist = {
            ...cookies.customer,
            ...response.data.formData,
          };
          document.cookie =
            `${form}=` +
            JSON.stringify({ ...cookies, ...{ customer: customerExist } });
          moveToNextStep();
          setLoading(false);
        } else {
          setLoading(false);
          return;
        }
      })
      .catch(function (error) {
        console.log(error);
        setLoading(false);
        return;
      });
  };

  const goBackCoach = () => {
    setShowCoach(false);
    setCoach(false);
  };

  return (
    <div
      style={{
        backgroundImage: `url(./images${step?.styles?.background_image || ''})`,
      }}
      className="bg-cover flex flex-col justify-start lg:justify-between bg-no-repeat min-h-screen 
      bg-center-bottom relative"
    >
      {loading && <LoadingSpinner />}
      {mainLoading && (
        <>
          <div className="header-logo flex justify-center max-w-[1200px] mx-4 lg:mx-auto pt-8">
            <Link to="/">
              <img src="./images/tapouts-logo.png" width="140px" />
            </Link>
          </div>

          <ProgressBarPod data={podList} setLoading={setMainLoading} />
        </>
      )}

      {!showCoach && !loading && !mainLoading && (
        <>
          <div className="header-logo flex justify-center max-w-[1200px] mx-4 lg:mx-auto pt-8">
            <Link to="/">
              <img src="./images/tapouts-logo.png" width="140px" />
            </Link>
          </div>

          <ProgressBar progressPercentage={progressBar} />
          <div className="lg:mt-8 lg:w-[600px] z-[99] mx-auto relative lg:bg-white p-6 rounded-[24px] lg:shadow-sm">
            <div>
              <div
                dangerouslySetInnerHTML={{
                  __html: getRecallAnswer(step.heading),
                }}
                className="pl-4 font-black bookmania-bold text-[#130049] text-2xl"
              ></div>
              <div className="pl-4 mt-4 mb-2 text-[#130049] text-base">
                {step.sub_heading}
              </div>
            </div>
            {error && (
              <div className="text-[#FE4254] font-black mt-2 ">*{error}</div>
            )}
            {podList.pods.map((pod) => {
              return (
                <div
                  key={pod.pod_id}
                  className="py-6 ml-[18px] border-b flex items-center border-[#DBE0E4]"
                >
                  <div>
                    <input
                      type="radio"
                      value={pod.pod_id}
                      id={pod.pod_id}
                      checked={
                        selectedChoice.id == pod.id ||
                        customer?.podDocId == pod.id
                      }
                      name="option"
                      className="pl-3 h-4 w-4"
                      onChange={() => {
                        getPodDetails(pod);
                      }}
                    />
                  </div>
                  <div className="ml-4">
                    <label className="" htmlFor={pod.pod_id}>
                      {pod.day.length
                        ? `${getCapitalize(pod?.day)} at ${
                            pod.start_time
                          } (Pacific Time)`
                        : ''}
                    </label>
                    <div>
                      launching on{' '}
                      {new Date(pod.start_date)
                        .toDateString()
                        .replace(/^\S+\s/, '')}
                    </div>
                  </div>
                </div>
              );
            })}

            <div className="flex justify-between">
              <div className="mt-8 w-3/4">
                <input
                  className="w-full border rounded-full p-3 outline-none"
                  ref={otherRef}
                  onChange={() => setError('')}
                  defaultValue={customer?.other_pod || ''}
                  placeholder="I need a different time "
                />
              </div>
              <div className="mt-8">
                <button
                  onClick={() => otherPods(otherRef.current.value)}
                  className="bg-[#FE4254] px-8 py-4  flex items-center w-20 rounded-full text-white text-base"
                >
                  Ok
                </button>
              </div>
            </div>
          </div>
          <div className="w-full lg:w-[600px] px-6 lg:px-0 mx-auto flex justify-between items-center pb-8">
            <BackButton
              goBack={goBack}
              center={!customer?.podDocId ? true : false}
            />

            {(customer?.podDocId || customer?.other_pod) && (
              <div
                className={`mt-8 bg-[#FE4254]   px-4 py-2 flex items-center w-24 rounded-full`}
              >
                <button
                  type="submit"
                  className="text-white text-base flex items-center"
                  onClick={() => {
                    customer?.other_pod
                      ? otherPods(customer?.other_pod)
                      : getPod(selectedChoice.value);
                  }}
                >
                  <span className="pl-2  font-bold">Next</span>

                  <span className="pl-2">
                    <img src={'/images/forward_arrow.png'} />
                  </span>
                </button>
              </div>
            )}
          </div>
        </>
      )}

      {showCoach && (
        <Coach
          pod={showCoach}
          coach={coach}
          moveToNextStep={moveToNextStep}
          customer={customer}
          goBackCoach={goBackCoach}
        />
      )}
    </div>
  );
};

export default PodList;
