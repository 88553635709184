import React from 'react';
import { createRoot } from 'react-dom/client';
import App from './App';
import './index.css';
import './App.css';
import { ErrorBoundary } from './components/common/ErrorBoundary';

const container = document.getElementById('root');
const root = createRoot(container);
root.render(
  //commenting to enable drag and drop of rows
  // <React.StrictMode>
  <ErrorBoundary>
    <App />
  </ErrorBoundary>
  // </React.StrictMode>
);
