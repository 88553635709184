import axios from 'axios';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import { Link } from 'react-router-dom';
import { deleteCookie, retriveCookie } from '../../common/helper';
import LoadingSpinner from '../../common/Loading';
import log from '../../core/log';

const EndPage = ({ getRecallAnswer, formName }) => {
  const [loading, setLoading] = useState(true);
  const cookies = retriveCookie(formName);
  const customer = cookies?.customer;
  const answerCookie = retriveCookie(`${formName}-answer`);
  const answer = answerCookie?.answer;
  const navigate = useNavigate();
  const getStatusUpdateAnswer = () => {
    const updateArr = [];
    const paymentStepNo = customer.payment_step.step_no;
    answer.forEach((e) => {
      if (paymentStepNo < e.step_no && e.status == 'updated') {
        updateArr.push(e);
      }
    });
    return updateArr;
  };

  useEffect(() => {
    if (!formName || !customer) navigate('/');
  }, [location]);

  const getTapoutsFormResponse = async () => {
    setLoading(true);
    const responseData = await fetch(
      process.env.REACT_APP_GET_TAPOUTS_FORM_RESPONSE,
      {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          form_name: formName,
          doc_id: customer?.responseDocId,
        }),
      }
    ).then((res) => res.json());
    if (!responseData.success) {
      setLoading(false);
      console.log('Api error!! ' + responseData.message);
    } else {
      setLoading(false);
      if (!responseData.data.end_page) {
        await updateResponse();
      } else {
        console.log('already data updated');
      }
    }
  };

  const updateResponse = async () => {
    setLoading(true);
    const updatedSteps = getStatusUpdateAnswer();
    try {
      let data = {
        formData: { end_page: true },
        formName: formName,
        steps: updatedSteps,
        responseDocId: customer.responseDocId,
      };

      let config = {
        method: 'post',
        url: process.env.REACT_APP_TAPOUTS_FORM_RESPONSE,
        headers: {
          'Content-Type': 'application/json',
        },
        data: data,
      };
      axios(config)
        .then(async (result) => {
          if (result?.data?.message) {
            setLoading(false);
          } else {
            setLoading(false);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (err) {
      log('Error while validating mail id', err);
      setLoading(false);
    }
  };

  useEffect(() => {
    getTapoutsFormResponse();
  }, []);
  return (
    <>
      {loading && <LoadingSpinner />}
      <div className="relative success-bg h-screen">
        <div className="header-logo max-w-[1200px] mx-4 lg:mx-auto pt-8">
          <Link to="/">
            <img src="./images/tapouts-logo.png" width="140px" />
          </Link>
        </div>

        <div className="lg:flex max-w-[1200px] lg:min-h-[550px] h-calc-enrolment items-center px-4 lg:px-0 justify-between block mx-0 lg:mx-auto ">
          <div className="relative ml-0 block lg:z-10  lg:flex items-center mt-6 text-center lg:text-left lg:mt-0">
            <div className="text-left lg:pr-[40%]">
              <div
                dangerouslySetInnerHTML={{
                  __html: getRecallAnswer(
                    "YAY! You've taken the first step in helping <name> <span style='color:#FE4254'>to THRIVE</span>."
                  ),
                }}
                className="text-[32px] lg:text-[40px] bookmania-bold font-black leading-[110%] text-[#130049]"
              ></div>
              <div className="text-[32px] lg:text-[40px] bookmania-bold font-black leading-[110%] text-[#130049]">
                {`We'll take it from here!`}
              </div>
              <div
                dangerouslySetInnerHTML={{
                  __html: getRecallAnswer(
                    "<name> has been added to the waitlist and we'll be in touch with more information shortly."
                  ),
                }}
                className="text-2xl font-normal leading-[110%] text-[#130049] mt-4 lg:mt-8"
              ></div>
              <div className="relative lg:mt-4 mt-8 flex items-center">
                <a href="https://tapouts.com/">
                  <button
                    onClick={deleteCookie}
                    className="bg-[#FE4254] justify-center px-4 py-2 flex items-center w-[182px] h-12 rounded-full"
                  >
                    <div className="text-white text-base">Access the site</div>
                    <div className="pl-3">
                      <svg
                        width="14"
                        height="10"
                        viewBox="0 0 17 12"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M15.5 5.912H1.5"
                          stroke="white"
                          strokeWidth="1.5"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <path
                          d="M10.5 10.912L15.5 5.912"
                          stroke="white"
                          strokeWidth="1.5"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <path
                          d="M10.5 0.912003L15.5 5.912"
                          stroke="white"
                          strokeWidth="1.5"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>
                    </div>
                  </button>
                </a>
              </div>
            </div>
          </div>
          <div className="fixed top-0 hidden lg:block right-0 success-right"></div>
        </div>
      </div>
    </>
  );
};

EndPage.propTypes = {
  getRecallAnswer: PropTypes.func,
  formName: PropTypes.string,
};

export default EndPage;
