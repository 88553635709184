import PropTypes from 'prop-types';
import { useEffect } from 'react';
import { Link } from 'react-router-dom';
import BackButton from '../common/BackButton';
const Coach = ({ coach, moveToNextStep, pod, customer, goBackCoach }) => {
  useEffect(() => {
    window.dataLayer.push({
      event: 'pageview',
      page: {
        title: 'coach',
      },
    });
  }, []);

  return (
    <div className="background-blurp bg-cover min-h-screen bg-no-repeat">
      <div className="relative">
        <div className="header-logo flex justify-start lg:justify-center max-w-[1200px] py-4 mx-4 lg:mx-auto">
          <Link to="/">
            <img src="./images/tapouts-logo.png" width="140px" />
          </Link>
        </div>
      </div>
      <div className="blurp-overall-section max-w-[600px] mx-auto px-5 lg:px-0 mt-6 lg:mt-20">
        <div className="blurp-head text-[40px] bookmania-bold font-black text-[#130049] text-left lg:text-center pb-5 leading-[110%]">
          <span className="text-[#1ee2ee]">{customer.child_name}</span> will be
          with other {pod.age_group} year olds!
        </div>
        <div className="coach-name text-2xl leading-[33.6px] text-[#130049] text-left lg:text-center">
          Meet Coach{' '}
          <span className="text-[#fe4254] font-black">
            {coach.first_name + ' ' + coach.middle_name + ' ' + coach.last_name}
            !
          </span>
        </div>
        <div
          className={`coach-image ${
            coach.image_url ? 'bg-[#fe4254]' : 'bg-gray-200'
          }  w-[200px] h-[200px] rounded-[100px] lg:mx-auto flex mt-8`}
        >
          <img
            className="rounded-[100px]"
            src={
              coach.image_url ? coach.image_url : './../images/profile_pic.svg'
            }
          />
        </div>
        <div className="content-text text-left text-[#130049] pt-10">
          {coach.short_bio}
        </div>
      </div>
      <div className="lg:max-w-[600px] px-6 pb-10 lg:px-0  w-full mx-auto flex justify-between items-center">
        <BackButton goBack={goBackCoach} />
        <div
          onClick={() => moveToNextStep()}
          className="mt-8 bg-[#FE4254]  px-4 py-2 flex items-center w-24 rounded-full cursor-pointer justify-center"
        >
          <div className="text-white text-white text-base flex items-center">
            Next
          </div>
          <div className="pl-2">
            <svg
              width="14"
              height="10"
              viewBox="0 0 17 12"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M15.5 5.912H1.5"
                stroke="white"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M10.5 10.912L15.5 5.912"
                stroke="white"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M10.5 0.912003L15.5 5.912"
                stroke="white"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </div>
        </div>
      </div>
    </div>
  );
};

Coach.propTypes = {
  coach: PropTypes.object,
  moveToNextStep: PropTypes.func,
  pod: PropTypes.object,
  customer: PropTypes.object,
  goBackCoach: PropTypes.func,
};

export default Coach;
