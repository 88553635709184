import PropTypes from 'prop-types';
import { useEffect } from 'react';
import { useNavigate } from 'react-router';
import BackButton from '../common/BackButton';
import { deleteCookie } from '../common/helper';

const Scale = ({
  getRecallAnswer,
  stepsData,
  setSelectedChoice,
  selectedChoice,
  storeAnswer,
  getDefaultValue,
  sortOptions,
  goBack,
  index,
}) => {
  const defaultData = getDefaultValue(stepsData);
  const styles = stepsData?.styles || {};
  const navigate = useNavigate();

  const validations = stepsData?.validations ? true : false;
  const onsubmit = (value) => {
    const finalData = {
      step_id: stepsData.id,
      type: stepsData.type,
      step_no: stepsData.step_no,
      variable: stepsData.variable ?? '',
      answer: value,
    };
    storeAnswer(finalData, stepsData);
  };
  const restart = () => {
    deleteCookie();
    navigate('/');
  };

  useEffect(() => {
    if (defaultData !== '') {
      setSelectedChoice({
        id: defaultData,
        value: stepsData.options[defaultData].value,
      });
    }
  }, [stepsData]);

  return (
    <>
      <div className="flex flex-col min-h-[65vh] justify-between">
        <div className="p-6 lg:p-8  max-w-[600px] mx-6 lg:mx-auto bg-white mt-6 rounded-[24px] z-10">
          <div className="text-2xl font-black bookmania-bold text-[#130049]">
            {stepsData?.index && (
              <span className="text-lg">{stepsData?.index}</span>
            )}
            <span
              dangerouslySetInnerHTML={{
                __html: getRecallAnswer(stepsData.heading),
              }}
              className="mb-8 block"
            ></span>
          </div>
          {stepsData.sub_heading ? (
            <div
              className="mb-10"
              dangerouslySetInnerHTML={{
                __html: getRecallAnswer(stepsData.sub_heading),
              }}
            ></div>
          ) : (
            ''
          )}
          <div className="block">
            <div className="justify-between flex proxima-nova gap-[8px]">
              {Object.keys(sortOptions(stepsData.options)).map((e1, i) => {
                return (
                  <div className="scale w-full" key={btoa(i)}>
                    <input
                      onChange={(e) => {
                        setSelectedChoice({
                          id: e.target.value,
                          value: stepsData.options[e.target.value].value,
                        });
                        onsubmit(e.target.value);
                      }}
                      required={
                        validations ? stepsData.validations.required : false
                      }
                      type="radio"
                      checked={selectedChoice?.id == e1 || defaultData == e1}
                      value={e1}
                      id={e1}
                      name="option"
                      className="hidden"
                    />
                    <label
                      style={{
                        background: `${stepsData.options[e1]?.background}`,
                      }}
                      className={`h-[54px] w-[52px] lg:h-auto lg:w-auto p-0 flex justify-center items-center flex lg:px-8 lg:py-4  text-2xl rounded-[16px]  cursor-pointer text-white font-black mt-2 text-center border-black mt-2`}
                      htmlFor={e1}
                    >
                      {stepsData.options[e1].value}
                    </label>
                  </div>
                );
              })}
            </div>
            <div className="flex justify-between mt-2">
              {Object.keys(sortOptions(stepsData.options)).map((opt, i) => {
                return (
                  <div
                    key={btoa(i)}
                    className={`mt-4  w-auto text-lg ${
                      stepsData.options[opt].heading == ''
                        ? 'mr-0 lg:mr-12'
                        : ''
                    }`}
                  >
                    {stepsData.options[opt].heading}
                  </div>
                );
              })}
            </div>
          </div>
          {stepsData?.index && index !== 0 && (
            <div className="text-xs mt-6 underline font-normal ">
              <span onClick={restart} className="cursor-pointer">
                Restart Assessment
              </span>
            </div>
          )}
        </div>
        <div className="max-w-[600px] w-full lg:pb-[64px] mx-auto flex justify-between items-center mt-8">
          {(index !== 0 || !stepsData.index) && (
            <BackButton goBack={goBack} center={!defaultData ? true : false} />
          )}

          {defaultData && (
            <div
              className={`mt-8 bg-[${styles?.btn_bg_colour || '#FE4254'}] ${
                !index && ''
              }   px-4 py-2 flex items-center w-24 rounded-full`}
            >
              <button
                type="submit"
                className=" text-white text-base flex items-center"
                onClick={() => {
                  onsubmit(selectedChoice.id);
                }}
              >
                <span className="pl-2 font-bold">
                  {styles?.btn_content || 'Next'}
                </span>

                <span className="pl-2">
                  <img src={'/images/forward_arrow.png'} />
                </span>
              </button>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

Scale.propTypes = {
  getRecallAnswer: PropTypes.func,
  stepsData: PropTypes.object,
  setSelectedChoice: PropTypes.func,
  selectedChoice: PropTypes.object,
  storeAnswer: PropTypes.func,
  getDefaultValue: PropTypes.func,
  sortOptions: PropTypes.func,
  goBack: PropTypes.func,
  index: PropTypes.number,
};

export default Scale;
