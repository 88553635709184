import { Routes, Route } from 'react-router-dom';
import Assessment from '../assessment/Assessment';
import PodList from '../assessment/PodList';
import AlreadyTaken from '../assessment/StartEnrollement/AlreadyTaken/AlreadyTaken';
import Dashboard from '../assessment/StartEnrollement/Dashboard';
import PageNotFound from '../common/PageNotFound';
import Payment from '../subscription/Payment';
import PaymentLoading from '../subscription/PaymentLoading';
import SuccessPage from '../subscription/SuccessPage';
import PodStaticSuccess from '../Types/Static/PodStaticSuccess';

const TapoutsRoutes = () => {
  return (
    <Routes>
      <Route path="/" element={<Dashboard />} />
      <Route path="/enrollment" element={<Assessment />} />
      <Route path="*" element={<PageNotFound />} />
      <Route path="/podlist" element={<PodList />} />
      <Route path="/payment" element={<Payment />} />
      <Route path="/success" element={<SuccessPage />} />
      <Route path="/paymentloading" element={<PaymentLoading />} />
      <Route path="/podsuccess" element={<PodStaticSuccess />} />
      <Route path="/alreadytaken" element={<AlreadyTaken />} />
    </Routes>
  );
};

export default TapoutsRoutes;
