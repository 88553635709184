import axios from 'axios';
import PropTypes from 'prop-types';
import { useEffect, useRef, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import LoadingSpinner from '../../../common/Loading';
import log from '../../../core/log';
import ChildResponse from './ChildResponse';
import { useLocation } from 'react-router';
import BackButton from './../../../common/BackButton';
import { deleteCookie } from '../../../common/helper';
const AlreadyTaken = () => {
  const location = useLocation();
  const { form_name } = location.state || '';
  const [loading, setLoading] = useState(false);
  const [addedChildResponse, setAddedChildResponse] = useState([]);
  const emailRef = useRef();
  const navigate = useNavigate();
  const [error, setError] = useState('');

  useEffect(() => {
    if (!form_name) navigate('/');
  }, [location]);

  const storeCookies = async (child) => {
    setLoading(true);
    const cookieObj = {};
    const answerObj = {};
    try {
      let config = {
        method: 'post',
        url: process.env.REACT_APP_GET_EXISTING_DATA,
        headers: {
          'Content-Type': 'application/json',
        },
        data: { responseDocId: child.responseDocId, form_name: form_name },
      };
      const responseData = await axios(config);
      answerObj.answer = responseData.data.data;
      cookieObj.variable = { '<name>': child.child_name };
      cookieObj.customer = child;
      document.cookie = `${form_name}=` + JSON.stringify(cookieObj);
      document.cookie = `${form_name}-answer=` + JSON.stringify(answerObj);
      setLoading(false);
    } catch (err) {
      log("Error while getting response of user's child", err);
      setLoading(false);
    }
  };

  const startHalfWay = async (e) => {
    e.preventDefault();
    const emailId = emailRef.current.value;
    if (!emailId) return setError('Please enter a valid email');
    const pattern = /[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
    const result = pattern.test(emailId);
    if (!result) {
      emailRef.current.value = '';
      setError('Please enter a valid email');
      return;
    }
    setLoading(true);
    try {
      let data = {
        form_name: form_name,
        email: emailId,
      };
      let config = {
        method: 'post',
        url: process.env.REACT_APP_GET_TYPEFORM_ANSWER,
        headers: {},
        data: data,
      };
      const result = await axios(config);
      const responseData = result.data.data;
      if (responseData.length) {
        const fillteredArr = responseData.filter((e) => {
          if (!e.pod_selection || !e.subscription) return e;
        });
        if (!fillteredArr.length) {
          setError('Account already exists');
        }

        setAddedChildResponse(fillteredArr);
        setLoading(false);
      } else {
        setError("You haven't taken this assessment");
        setLoading(false);
      }
    } catch (err) {
      log('Error while getting assessement data' + err);
      setLoading(false);
    }
  };

  const getChildDetails = (child) => {
    if (child == 'new') {
      deleteCookie();
      navigate('/enrollment', {
        state: {
          form: form_name,
        },
      });
    } else {
      NavigateChild(child);
    }
  };
  const NavigateChild = async (child) => {
    const podSelection = child?.pod_selection;
    const subscription = child?.subscription;

    await storeCookies(child);

    if (!podSelection || (!child?.payment_visited && !subscription)) {
      navigate('/podlist', {
        state: {
          form: form_name,
          step: child.pod_step,
        },
      });
    } else if (child?.payment_visited && !subscription && podSelection) {
      navigate('/payment', {
        state: {
          form: form_name,
          step: child.payment_step,
        },
      });
    }
  };

  const goBack = () => {
    navigate('/');
  };

  return (
    <>
      {loading && <LoadingSpinner />}
      <>
        {!addedChildResponse.length ? (
          <>
            <div className="relative return-customer-bg">
              <div className="header-logo lg:max-w-[1300px] px-4 lg:mx-auto pt-3 lg:pt-8 -z-1">
                <Link to="/">
                  <img src="./images/tapouts-logo.png" width="140px" />
                </Link>
              </div>

              <div className="lg:flex max-w-[1300px] lg:min-h-[550px] h-calc-enrolment items-center px-4 justify-between block pb-16 lg:pb-0 mx-0 lg:mx-auto ">
                <div className="relative ml-0 block lg:z-10  lg:flex items-center mt-6 text-center lg:text-left lg:mt-0">
                  <div>
                    <div className="flex bookmania justify-start items-center text-left">
                      <div className="text-[26px] lg:text-[52px] font-black text-[#130049]">
                        Great to see you again!
                      </div>

                      <div className="pl-2">
                        <img
                          src="./images/happy.png"
                          width="36px"
                          height="36px"
                        />
                      </div>
                    </div>
                    <div className="text-[20px] lg:text-lg font-normal text-[#130049] text-left">
                      What email did you use to complete the assessment?
                    </div>
                    {error && (
                      <div className="text-[#FE4254] font-black mt-2 ">
                        *{error}
                      </div>
                    )}

                    <form
                      onSubmit={startHalfWay}
                      className="relative mt-10 flex items-center"
                    >
                      <input
                        type="text"
                        ref={emailRef}
                        onChange={() => setError('')}
                        className="border-[#DBE0E4] border rounded-full pl-16 py-3 flex justify-between w-full pr-48 bg-transparent"
                        placeholder="Enter your email"
                      />

                      <div className="absolute top-3 left-8">
                        <img src="./images/mail_congrats.png" />
                      </div>
                      <button
                        type="submit"
                        className={`absolute right-2 top-1 bg-[#FE4254] justify-center px-4 py-2 flex items-center w-[153px] rounded-full text-center mx-auto`}
                      >
                        <div className="text-white text-base">Continue</div>
                      </button>
                    </form>
                    <div className="lg:max-w-[600px] text-lg w-full mt-4 px-5 lg:px-0 mx-auto flex justify-between items-center">
                      <BackButton goBack={goBack} />
                    </div>
                  </div>
                </div>
                <div className="hidden lg:block fixed top-0 right-0 return-ride-side-pic z-0"></div>
              </div>
            </div>
            <div className="lg:hidden block return-mobile-bg"></div>
          </>
        ) : (
          <>
            <ChildResponse
              childs={addedChildResponse}
              submit={getChildDetails}
              setAddedChildResponse={setAddedChildResponse}
            />
          </>
        )}
      </>
      <div className="image"></div>
    </>
  );
};

AlreadyTaken.propTypes = {
  addedChildResponse: PropTypes.array,
  emailRef: PropTypes.object,
  startHalfWay: PropTypes.func,
  startSelectedChild: PropTypes.func,
  setAddedChildResponse: PropTypes.func,
  form_name: PropTypes.string,
};

export default AlreadyTaken;
