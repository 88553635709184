import PropTypes from 'prop-types';
import { useNavigate } from 'react-router';
import BackButton from '../common/BackButton';
import { deleteCookie } from '../common/helper';

const MultiChoiceQuestion = ({
  getRecallAnswer,
  stepsData,
  setSelectedChoice,
  selectedChoice,
  storeAnswer,
  getDefaultValue,
  sortOptions,
  goBack,
  index,
}) => {
  const validations = stepsData.validations ? true : false;
  const styles = stepsData?.styles || {};
  const navigate = useNavigate();
  const defaultData = getDefaultValue(stepsData);
  const onsubmit = (value) => {
    const finalData = {
      step_id: stepsData.id,
      type: stepsData.type,
      step_no: stepsData.step_no,
      variable: stepsData.variable ?? '',
      answer: value,
    };
    storeAnswer(finalData, stepsData);
  };
  const restart = () => {
    deleteCookie();
    navigate('/');
  };
  return (
    <>
      <div className="flex flex-col min-h-[65vh] justify-between">
        <div className="lg:mt-8 w-full lg:w-[600px] transition-fade z-[99] mx-auto relative lg:bg-white p-6 rounded-[24px] lg:shadow-sm">
          <div className="flex items-center">
            <div>
              {stepsData?.index && (
                <div
                  style={{
                    color: stepsData?.styles?.secondary_colour || '#130049',
                    backgroundColor:
                      stepsData?.styles?.primary_colour || '#FBCD41',
                  }}
                  className="rounded-full w-8 h-8 text-center flex justify-center items-center"
                >
                  <span className="text-lg"> {stepsData?.index}</span>
                </div>
              )}
            </div>
            <div
              dangerouslySetInnerHTML={{
                __html: getRecallAnswer(
                  `${stepsData.heading}${
                    stepsData?.validations?.required
                      ? "<span style='color :#FE4254' >*</span>"
                      : ''
                  }`
                ),
              }}
              className="pl-4 text-[#130049] text-lg"
            ></div>
          </div>
          {stepsData.sub_heading ? (
            <div
              dangerouslySetInnerHTML={{
                __html: getRecallAnswer(stepsData.sub_heading),
              }}
            ></div>
          ) : (
            ''
          )}
          {Object.keys(sortOptions(stepsData.options)).map((e1, i) => {
            return (
              <div
                className="py-4 ml-[9px] border-b flex items-center border-[#DBE0E4] last:border-b-0"
                key={btoa(i)}
              >
                <div className="flex items-center">
                  <input
                    onChange={(e) => {
                      setSelectedChoice({
                        id: e.target.value,
                        value: stepsData.options[e.target.value],
                      });
                      onsubmit(e.target.value);
                    }}
                    required={
                      validations ? stepsData.validations.required : false
                    }
                    type="radio"
                    checked={selectedChoice.id == e1 || defaultData == e1}
                    value={e1}
                    id={e1}
                    name="option"
                    className="pl-3 h-4 w-4"
                  />
                </div>
                <div>
                  <label className="pl-2" htmlFor={e1}>
                    {stepsData.options[e1]}
                  </label>
                </div>
              </div>
            );
          })}
          {stepsData?.index && index !== 0 && (
            <div className="text-xs mt-6 underline font-normal ">
              <span onClick={restart} className="cursor-pointer">
                Restart Assessment
              </span>
            </div>
          )}
        </div>

        <div className="w-full lg:w-[600px] px-6 lg:px-0 mx-auto flex justify-between items-center pb-8">
          {(index !== 0 || !stepsData.index) && (
            <BackButton
              goBack={goBack}
              center={!getDefaultValue(stepsData) ? true : false}
            />
          )}

          {defaultData && (
            <div
              className={`mt-8 bg-[${
                styles?.btn_bg_colour || '#FE4254'
              }]   px-4 py-2 flex ${
                !index && ''
              } items-center w-24 rounded-full`}
            >
              <button
                type="submit"
                className="text-white text-base flex items-center"
                onClick={() => {
                  onsubmit(defaultData);
                }}
              >
                <span className="pl-2  font-normal">
                  {styles?.btn_content || 'Next'}
                </span>

                <span className="pl-2">
                  <svg
                    width="14"
                    height="10"
                    viewBox="0 0 17 12"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M15.5 5.912H1.5"
                      stroke="white"
                      strokeWidth="1.5"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M10.5 10.912L15.5 5.912"
                      stroke="white"
                      strokeWidth="1.5"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M10.5 0.912003L15.5 5.912"
                      stroke="white"
                      strokeWidth="1.5"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </span>
              </button>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

MultiChoiceQuestion.propTypes = {
  getRecallAnswer: PropTypes.func,
  stepsData: PropTypes.object,
  setSelectedChoice: PropTypes.func,
  selectedChoice: PropTypes.object,
  storeAnswer: PropTypes.func,
  getDefaultValue: PropTypes.func,
  sortOptions: PropTypes.func,
  goBack: PropTypes.func,
  index: PropTypes.number,
};

export default MultiChoiceQuestion;
