import PropTypes from 'prop-types';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router';
import BackButton from '../common/BackButton';
import { deleteCookie } from '../common/helper';
const Number = ({
  getRecallAnswer,
  stepsData,
  getDefaultValue,
  storeAnswer,
  goBack,
  index,
}) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm();
  const validations = stepsData.validations ? true : false;
  const subStep = Object.prototype.hasOwnProperty.call(stepsData, 'topic_id');
  const styles = stepsData?.styles || {};
  const navigate = useNavigate();

  const ErrorMessage = validations
    ? {
        required: '*Please Enter Your Answer',
        min: `*Please Enter your Answer with minimum limit of
    ${stepsData.validations.min}`,
        max: `*Please Enter your Answer with maximum limit of 
    ${stepsData.validations.max}`,
      }
    : {};
  const restart = () => {
    deleteCookie();
    navigate('/');
  };

  const onSubmit = (obj) => {
    const finalData = {
      step_id: stepsData.id,
      type: stepsData.type,
      step_no: stepsData.step_no,
      variable: stepsData.variable ?? '',
      answer: obj.number,
    };
    storeAnswer(finalData, stepsData);
    reset({ number: '' });
  };
  return (
    <>
      <form
        className="flex flex-col min-h-[65vh] justify-between"
        onSubmit={handleSubmit(onSubmit)}
      >
        <div className="z-10 transition-fade w-full lg:w-[600px] delay-500 mx-auto relative lg:bg-white p-6 rounded-[24px] lg:shadow-sm">
          <div className="flex items-center">
            {stepsData?.index && (
              <div
                className={`rounded-full w-8 h-8 text-center flex justify-center items-center bg-[${
                  stepsData?.styles?.primary_colour
                    ? stepsData?.styles?.primary_colour
                    : '#FBCD41'
                }]`}
              >
                <span
                  className={` ${
                    subStep ? 'text-[#ffff]' : 'text-[#130049]'
                  }  block text-lg`}
                >
                  {stepsData?.index}
                </span>
              </div>
            )}
            <div
              dangerouslySetInnerHTML={{
                __html: getRecallAnswer(
                  `${stepsData.heading}${
                    stepsData?.validations?.required
                      ? "<span style='color :#FE4254' >*</span>"
                      : ''
                  }`
                ),
              }}
              className="pl-4 text-[#130049] text-lg"
            ></div>
          </div>
          {stepsData.sub_heading ? (
            <div
              dangerouslySetInnerHTML={{
                __html: getRecallAnswer(stepsData.sub_heading),
              }}
            ></div>
          ) : (
            ''
          )}

          {validations && errors?.number && (
            <div className="text-red-600 font-bold mt-4">
              {ErrorMessage[errors.number.type]}
            </div>
          )}
          <div className="mt-8">
            <input
              {...register(
                'number',
                validations
                  ? {
                      required: stepsData.validations.required,
                      min:
                        stepsData.validations.min !== ''
                          ? stepsData.validations.min
                          : 0,
                      max:
                        stepsData.validations.max !== ''
                          ? stepsData.validations.max
                          : Infinity,
                    }
                  : {}
              )}
              placeholder={
                stepsData?.placeholder ? stepsData?.placeholder : 'Type here'
              }
              type="number"
              onWheel={() => document.activeElement.blur()}
              defaultValue={getDefaultValue(stepsData)}
              className="w-full border rounded-full p-3 outline-none"
            />
          </div>
          {stepsData?.index && index !== 0 && (
            <div className="text-xs mt-6 underline font-normal ">
              <span onClick={restart} className="cursor-pointer">
                Restart Assessment
              </span>
            </div>
          )}
        </div>
        <div className="lg:max-w-[600px] lg:pb-[64px] px-5 lg:px-0 w-full mx-auto flex justify-between items-center">
          {(index !== 0 || !stepsData.index) && <BackButton goBack={goBack} />}

          <div
            className={`mt-8  bg-[${
              styles?.btn_bg_colour || '#FE4254'
            }]   px-4 py-2 flex items-center w-24 rounded-full
             ${!index && 'mx-auto'}`}
          >
            <button
              type="submit"
              className="text-white text-white text-base flex items-center"
            >
              <span className="pl-2">{styles?.btn_content || 'Next'}</span>

              <span className="pl-2">
                <svg
                  width="14"
                  height="10"
                  viewBox="0 0 17 12"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M15.5 5.912H1.5"
                    stroke="white"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M10.5 10.912L15.5 5.912"
                    stroke="white"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M10.5 0.912003L15.5 5.912"
                    stroke="white"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </span>
            </button>
          </div>
        </div>
      </form>
    </>
  );
};

Number.propTypes = {
  getRecallAnswer: PropTypes.func,
  stepsData: PropTypes.object,
  getDefaultValue: PropTypes.func,
  storeAnswer: PropTypes.func,
  goBack: PropTypes.func,
  index: PropTypes.number,
};

export default Number;
