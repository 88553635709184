import axios from 'axios';
import { retriveCookie } from '../common/helper';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import CheckoutForm from './CheckoutForm';
import LoadingSpinner from '../common/Loading';
import { useLocation } from 'react-router';
import log from '../core/log';

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHED_KEY);
const Payment = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { form } = location.state || '';
  const { step } = location.state || '';
  const [loading, setLoading] = useState(true);
  const [stripeData, setStripeData] = useState(null);
  const [clientSecret, setClientSecret] = useState('');
  const cookies = retriveCookie(form);
  const customer = cookies?.customer;
  const answerCookie = retriveCookie(`${form}-answer`);

  console.log(step);

  const getTapoutsFormResponse = async () => {
    const responseData = await fetch(
      process.env.REACT_APP_GET_TAPOUTS_FORM_RESPONSE,
      {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          form_name: form,
          doc_id: customer?.responseDocId,
        }),
      }
    ).then((res) => res.json());
    if (!responseData.success) {
      console.log('Api error!! ' + responseData.message);
    } else {
      if (!responseData?.data?.payment_visited) {
        const newCustomerData = await updateResponse();
        await createCustomer(newCustomerData);
      } else if (!responseData?.data?.subscription) {
        await createCustomer(responseData.data);
      } else {
        navigate('/enrollment', {
          state: {
            step_no: step.step_no,
            form: form,
          },
        });
      }
    }
  };
  useEffect(() => {
    if (!form || !step) navigate('/');
    getTapoutsFormResponse();
  }, [location]);

  const getStatusUpdateAnswer = () => {
    const updateArr = [];
    const podStepNo = customer.pod_step.step_no;
    answerCookie?.answer.forEach((e) => {
      if (
        podStepNo < e.step_no &&
        e.status == 'updated' &&
        step.step_no > e.step_no
      ) {
        updateArr.push(e);
      }
    });
    return updateArr;
  };

  const updateResponse = async () => {
    setLoading(true);
    const updatedSteps = getStatusUpdateAnswer();

    let tempObj = {
      payment_step: { ...step },
      status: step?.extra_variable?.status,
      payment_visited: true,
    };

    try {
      let data = {
        formData: tempObj,
        formName: form,
        steps: updatedSteps,
        responseDocId: customer.responseDocId,
      };

      let config = {
        method: 'post',
        url: process.env.REACT_APP_TAPOUTS_FORM_RESPONSE,
        headers: {
          'Content-Type': 'application/json',
        },
        data: data,
      };
      const result = await axios(config);

      if (result?.data?.message) {
        console.log(result?.data?.message);
        setLoading(false);
      } else {
        const customerExist = {
          ...cookies.customer,
          ...result.data.formData,
        };
        document.cookie =
          `${form}=` +
          JSON.stringify({ ...cookies, ...{ customer: customerExist } });
        setLoading(false);
        return customerExist;
      }
    } catch (err) {
      log('Error while updaing response', err);
      setLoading(false);
    }
  };

  const appearance = {
    theme: 'stripe',
    variables: {
      borderRadius: '80px',
    },
  };
  const options = {
    clientSecret,
    appearance,
  };

  const customerCreation = async (customerData) => {
    const customerDetails = await fetch(
      `${process.env.REACT_APP_STRIPE_PAYMENT_URL}/create-customer`,
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          email: customerData.email,
          name: customerData.child_name,
        }),
      }
    );
    return await customerDetails.json();
  };

  const createClientSecretKey = async (customerId) => {
    try {
      const result = await fetch(
        `${process.env.REACT_APP_STRIPE_PAYMENT_URL}/create-setupIntent`,
        {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({
            customerId: customerId,
          }),
        }
      ).then((res) => res.json());
      return result;
    } catch (err) {
      log('Error While creating setUpIntent', err);
    }
  };

  const saveStripeResponse = async (customer, customerId, setupIntent) => {
    let obj = {
      stripe_customer_id: customerId,
      setup_intent: setupIntent,
    };
    let data = {
      formData: obj,
      formName: form,
      responseDocId: customer.responseDocId,
    };

    let config = {
      method: 'post',
      url: process.env.REACT_APP_TAPOUTS_FORM_RESPONSE,
      headers: {
        'Content-Type': 'application/json',
      },
      data: data,
    };

    axios(config)
      .then(function (response) {
        if (response.data.success) {
          const customerExist = {
            ...customer,
            ...response.data.formData,
          };
          document.cookie =
            `${form}=` +
            JSON.stringify({ ...cookies, ...{ customer: customerExist } });
        } else {
          return;
        }
      })
      .catch(function (error) {
        console.log(error);
        return;
      });
  };

  const getSetupIntent = async (setupIntentKey) => {
    try {
      const clientSetupIntent = await fetch(
        `${process.env.REACT_APP_STRIPE_PAYMENT_URL}/get-setupIntent`,
        {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({
            setupIntentKey: setupIntentKey,
          }),
        }
      ).then((res) => res.json());
      return clientSetupIntent;
    } catch (err) {
      console.log('Stripe error!! ' + err.message);
    }
  };

  const createCustomer = async (customerData) => {
    let stripeData;
    let customerId = '';
    let setupIntent;
    let clientSecret;
    if (customerData?.stripe_customer_id) {
      customerId = customerData.stripe_customer_id;
      if (customerData?.setup_intent) {
        const clientSetupIntent = await getSetupIntent(
          customerData?.setup_intent
        );
        clientSecret = clientSetupIntent?.client_secret;
      } else {
        const result = await createClientSecretKey(customerId);
        setupIntent = result.id;
        clientSecret = result.client_secret;
        await saveStripeResponse(customerData, customerId, setupIntent);
      }
    } else {
      const stripeCustomerData = await customerCreation(customerData);
      customerId = stripeCustomerData.id;
      const result = await createClientSecretKey(customerId);
      setupIntent = result.id;
      clientSecret = result.client_secret;
      await saveStripeResponse(customerData, customerId, setupIntent);
    }
    if (customerId && clientSecret) {
      stripeData = {
        priceId: process.env.REACT_APP_STRIPE_PRICE_ID,
        customerId: customerId,
      };
      setClientSecret(clientSecret);
      setLoading(false);
      setStripeData(stripeData);
    } else {
      console.log('customer is not created');
      setLoading(false);
    }
  };

  return (
    <div>
      {loading && <LoadingSpinner />}
      {clientSecret && customer?.responseDocId && !loading && (
        <Elements options={options} stripe={stripePromise}>
          <CheckoutForm
            stripeData={stripeData}
            id={customer?.responseDocId}
            formName={form}
            step={step}
          />
        </Elements>
      )}
      {!clientSecret && <LoadingSpinner />}
    </div>
  );
};

export default Payment;
